import { Preset, Sound } from '@bpm-web-app/create-api-sdk';
import { ReactNode, createContext, useState, useMemo, useCallback } from 'react';
import { RenderLocationType } from './three-dots-sheet.context';

export type CreateActionType = 'pack' | 'curated-set' | 'sound' | 'preset' | 'drive-preset' | 'label' | 'drive' | 'mini-player' | 'mini-player-demos' | 'drive-sound' | 'download-sound' | 'download-preset';

export type CreateThreeDotsSheetOptionsKeys =
    | 'play-demo'
    | 'play-demo-2'
    | 'add-to-favorites'
    | 'find-similar'
    | 'download'
    | 'download-credits'
    | 'download-midi'
    | 'download-preset-credits'
    | 'download-midi-credits'
    | 'download-pack'
    | 'go-to-pack'
    | 'add-to-drive'
    | 'report'
    | 'demo-01'
    | 'demo-02'
    | 'edit-drive-name'
    | 'delete-drive'
    | 'remove-from-drive'
    | 'share';

type ObjectTypeSound = {
    type: 'Sound',
    obj: Sound
};
type ObjectTypePreset = {
    type: 'Preset',
    obj: Preset
};

export type ObjectType = ObjectTypePreset | ObjectTypeSound;

export type CreateThreeDotsArgs = {
    newCreateActionType: CreateActionType;
    shareUrl?: string;
    actionId: CreateThreeDotsSheetContextProperties['actionTypeId'];
    left: number;
    top: number;
    showExtraPlayDemoOption?: boolean;
    actionsToHide?: CreateThreeDotsSheetOptionsKeys[];
    secondaryActionId?: CreateThreeDotsSheetContextProperties['secondaryCreateActionTypeId'];
    onlyDrives?: boolean;
    object?: ObjectType;
    renderLocationPosition?: RenderLocationType,
    onActionCompleted?: () => void
};

const packActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['play-demo', 'add-to-favorites', 'find-similar'];

const packWithExtraPlayActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['play-demo', 'play-demo-2', 'add-to-favorites', 'find-similar'];

const curatedActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['add-to-favorites'];

const soundActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['download', 'download-midi', 'go-to-pack', 'add-to-favorites', 'add-to-drive', 'find-similar', 'report'];

const presetActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['download', 'go-to-pack', 'add-to-favorites', 'add-to-drive', 'report'];

const soundDownloadActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['download-credits', 'download-midi-credits'];

const presetDownloadActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['download-preset-credits'];

const labelActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['add-to-favorites'];

const miniPlayerActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['download-pack', 'add-to-favorites', 'find-similar'];

const miniPlayerDemosActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['demo-01', 'demo-02'];

const myDriveActiveOptions: CreateThreeDotsSheetOptionsKeys[] = ['edit-drive-name', 'delete-drive'];

export interface CreateThreeDotsSheetContextProperties {
    setCreateActionTypeId: (id: number | string) => void;
    onActionCompleted?: () => void
    actionTypeId: number | string;
    /** Opens the 3 dots menu with the options based in the `CreateActionType` specified.
     * Content ID should be passed, along with left and top absolute position on the pressed button.
     */
    openThreeDotsModalSheet: (args: CreateThreeDotsArgs) => void;
    /** Active options in the 3 dots menu */
    optionsToShow: CreateThreeDotsSheetOptionsKeys[];
    shareUrl: string;
    /** Current CreateActionType */
    actionType: CreateActionType;
    leftPosition: number;
    topPosition: number;
    setSecondaryCreateActionTypeId: (id: number | string | null) => void;
    move: (x: number, y: number) => void;
    secondaryCreateActionTypeId: string | number | null;
    showOnlyDrives?: boolean;
    object?: ObjectType;
    renderLocation?: RenderLocationType;
}

export const CreateThreeDotsSheetContext = createContext<CreateThreeDotsSheetContextProperties>({
    setCreateActionTypeId: () => null,
    onActionCompleted: () => null,
    /** Initial Type ID is -1 so we can check if we should present 3 dots components or not */
    actionTypeId: -1,
    openThreeDotsModalSheet: () => null,
    optionsToShow: [],
    shareUrl: undefined,
    leftPosition: 0,
    topPosition: 0,
    actionType: 'pack',
    setSecondaryCreateActionTypeId: () => null,
    move: () => null,
    secondaryCreateActionTypeId: null,
    showOnlyDrives: false,
    renderLocation: 'app',
});

export interface CreateThreeDotsSheetProviderProps {
    children: ReactNode;
    initialState?: {
        actionTypeId: number | string;
        actionType?: CreateActionType;
        leftPosition?: number;
        topPosition?: number;
        optionsToShow?: CreateThreeDotsSheetOptionsKeys[];
        shareUrl: string;
    };
}

export function CreateThreeDotsSheetProvider({ children, initialState }: CreateThreeDotsSheetProviderProps) {
    const [actionType, setCreateActionType] = useState<CreateActionType>(initialState?.actionType || 'pack');
    const [actionTypeId, setCreateActionTypeId] = useState<CreateThreeDotsSheetContextProperties['actionTypeId']>(initialState?.actionTypeId || -1);
    const [secondaryCreateActionTypeId, setSecondaryCreateActionTypeId] = useState<CreateThreeDotsSheetContextProperties['secondaryCreateActionTypeId']>(null);
    const [leftPosition, setLeftPosition] = useState<number>(initialState?.leftPosition || 0);
    const [topPosition, setTopPosition] = useState<number>(initialState?.topPosition || 0);
    const [showOnlyDrives, setShowOnlyDrives] = useState<boolean>(false);
    const [object, setObejct] = useState<ObjectType>();
    const [onActionCompleted, setOnActionCompleted] = useState<() => void>();

    const [renderLocation, setRenderLocation] = useState<CreateThreeDotsSheetContextProperties['renderLocation']>('app');

    const [optionsToShow, setOptionsToShow] = useState<CreateThreeDotsSheetOptionsKeys[]>(initialState?.optionsToShow || []);
    const [shareUrl, setShareUrl] = useState<string>(initialState?.shareUrl);

    const move = useCallback((x: number, y: number) => {
        setLeftPosition(x);
        setTopPosition(y);
    }, [setLeftPosition, setTopPosition]);

    const value = useMemo(
        () => ({
            actionType,
            actionTypeId,
            optionsToShow,
            shareUrl,
            leftPosition,
            topPosition,
            showOnlyDrives,
            object,
            onActionCompleted,
            openThreeDotsModalSheet: ({
                newCreateActionType,
                actionId,
                left,
                top,
                showExtraPlayDemoOption,
                actionsToHide,
                secondaryActionId,
                onlyDrives = false,
                shareUrl: newShareUrl,
                object: newObject,
                renderLocationPosition = 'app',
                onActionCompleted: newOnActionCompleted
            }: CreateThreeDotsArgs) => {
                /* if three dots menu already open and the next click was done on the same
                 * three dots icon as the first one, close the one that's currently open */
                if (actionId !== -1 && actionTypeId === actionId) {
                    setCreateActionTypeId(-1);
                    return;
                }

                if (actionId) {
                    setCreateActionTypeId(actionId);
                }

                if (secondaryActionId) {
                    setSecondaryCreateActionTypeId(secondaryActionId);
                }

                if (renderLocationPosition) {
                    setRenderLocation(renderLocationPosition);
                } else {
                    setRenderLocation('app');
                }

                setOnActionCompleted(() => newOnActionCompleted);

                setObejct(newObject);

                setShareUrl(newShareUrl);

                setShowOnlyDrives(onlyDrives);

                const activeOptions = (): CreateThreeDotsSheetOptionsKeys[] => {
                    let options: CreateThreeDotsSheetOptionsKeys[] = [];
                    switch (newCreateActionType) {
                        case 'pack': {
                            options = showExtraPlayDemoOption ? packWithExtraPlayActiveOptions : packActiveOptions;
                            break;
                        }
                        case 'curated-set': {
                            options = curatedActiveOptions;
                            break;
                        }
                        case 'sound': {
                            options = soundActiveOptions;
                            break;
                        }
                        case 'preset': {
                            options = presetActiveOptions;
                            break;
                        }
                        case 'drive-preset': {
                            options = [...presetActiveOptions, 'remove-from-drive'];
                            break;
                        }
                        case 'download-preset': {
                            options = presetDownloadActiveOptions;
                            break;
                        }
                        case 'download-sound': {
                            options = soundDownloadActiveOptions;
                            break;
                        }
                        case 'drive-sound': {
                            options = [...soundActiveOptions, 'remove-from-drive'];
                            break;
                        }
                        case 'label': {
                            options = labelActiveOptions;
                            break;
                        }
                        case 'mini-player': {
                            options = miniPlayerActiveOptions;
                            break;
                        }
                        case 'mini-player-demos': {
                            options = miniPlayerDemosActiveOptions;
                            break;
                        }
                        case 'drive': {
                            options = myDriveActiveOptions;
                            break;
                        }
                        default: {
                            return [];
                        }
                    }

                    if (actionsToHide) return options.filter((option) => !actionsToHide.includes(option));

                    return options;
                };

                setOptionsToShow(activeOptions);
                setCreateActionType(newCreateActionType);
                setTopPosition(top);
                setLeftPosition(left);
            },
            move,
            setCreateActionTypeId,
            secondaryCreateActionTypeId,
            setSecondaryCreateActionTypeId,
            renderLocation,
        }),
        [actionType, actionTypeId, optionsToShow, shareUrl, leftPosition, topPosition, showOnlyDrives, object, move, secondaryCreateActionTypeId, renderLocation, onActionCompleted]
    );

    return <CreateThreeDotsSheetContext.Provider value={value}>{children}</CreateThreeDotsSheetContext.Provider>;
}
