import { appendQueryParams, createAppRoutes, HLSPlayer, parseSeconds, State as PlayerStateEnum, usePlayerState } from '@bpm-web-app/utils';
import classNames from 'classnames';
import router from 'next/router';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import Link from 'next/link';
import PauseIcon from '../../assets/icons/player-pause.svg';
import PlayIcon from '../../assets/icons/player-play.svg';
import VolumeMuteIcon from '../../assets/icons/player-volume-off.svg';
import VolumeIcon from '../../assets/icons/player-volume.svg';
import { useCreatePlayer } from '../../../../utils/src/lib/create-player.context';
import BpmLogo from '../../assets/icons/bpm-logo.svg';
import ArrowDownTriangle from '../../assets/icons/arrow-down-triangle.svg';
import { CreatePlayerWave } from '../shared/player-wave/player-wave-create';
import { CreateThreeDotsSheetContext } from '../shared/three-dots-sheet/create-three-dots-sheet.context';
import { sliderStyles } from './common';
import styles from './mini-player.module.css';
import { ThreeDotsButton } from '../shared/three-dots-button/three-dots-button';

export function CreateMiniPlayer() {
    const { currentTrack, elapsed, volume, setVolume, togglePlayPause } = useCreatePlayer();

    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);
    const volumeSliderRef = useRef<HTMLDivElement>(null);
    const playerState = usePlayerState();

    const hasDemosDropdown = useMemo(() => !!currentTrack?.demo_wave_2, [currentTrack?.demo_wave_2]);

    const handleUserKeyPress = useCallback(
        (event) => {
            if (document.activeElement?.className.includes('input')) {
                return;
            }

            const { key } = event;

            if (key === ' ') {
                event.preventDefault();
                togglePlayPause();
            }
        },
        [togglePlayPause]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    if (!currentTrack) return null;

    return (
        <div className={styles['mini-player']}>
            <div className={styles['mini-player__container--create']}>
                <div className={styles['mini-player__details']}>
                    <div
                        role="button"
                        tabIndex={0}
                        aria-label="open packs page"
                        className={classNames(styles['mini-player__details-cover'], currentTrack.slug && styles['mini-player__details-cover--clickable'])}
                        onClick={() => {
                            if (currentTrack.slug) {
                                router.push(createAppRoutes.packsSlug(currentTrack.slug));
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && currentTrack.slug) {
                                router.push(createAppRoutes.packsSlug(currentTrack.slug));
                            }
                        }}
                    >
                        {currentTrack.artwork_url ? (
                            <img
                                src={appendQueryParams(currentTrack.artwork_url, { key: 'dw', value: 64 })}
                                srcSet={`${appendQueryParams(currentTrack.artwork_url, { key: 'dw', value: 128 })} 2x`}
                                alt={currentTrack.name}
                            />
                        ) : (
                            <BpmLogo />
                        )}
                    </div>
                    <div
                        className={classNames(styles['mini-player__details-title-wrapper'], {
                            [styles['mini-player__details-title-wrapper-has-right-fade']]: !hasDemosDropdown,
                        })}
                    >
                        <div className={styles['mini-player__details-demos-wrapper']}>
                            <div className={styles['mini-player__details-title-create']}>{currentTrack.name}</div>
                            {hasDemosDropdown && (
                                <button
                                    className={classNames(styles['mini-player__demos-button'], 'three-dots-button')}
                                    aria-label="2 Demos"
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openThreeDotsModalSheet({
                                            newCreateActionType: 'mini-player-demos',
                                            shareUrl: createAppRoutes.packsSlug(currentTrack.slug),
                                            actionId: currentTrack.id,
                                            left: e.currentTarget.getBoundingClientRect().left + e.currentTarget.getBoundingClientRect().width,
                                            top: e.currentTarget.getBoundingClientRect().top + window.scrollY - 80,
                                        });
                                    }}
                                >
                                    {currentTrack.playSecondDemo ? 'Demo 02' : 'Demo 01'}
                                    <ArrowDownTriangle />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles['mini-player__middle-section-create']}>
                    <div className={styles['mini-player__controls']}>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                togglePlayPause();
                            }}
                            className={classNames(styles['mini-player__control'], {
                                [styles['mini-player__control--play']]: playerState !== PlayerStateEnum.Playing,
                                [styles['mini-player__control--pause']]: playerState === PlayerStateEnum.Playing,
                            })}
                            type="button"
                            aria-label={playerState === PlayerStateEnum.Playing ? 'Pause' : 'Play'}
                        >
                            {playerState === PlayerStateEnum.Playing ? <PauseIcon /> : <PlayIcon />}
                        </button>
                    </div>
                    <div className={classNames(styles['mini-player__wave'], styles['mini-player__wave--create'])}>
                        <div className={styles['mini-player__elapsed']}>{parseSeconds(elapsed)}</div>
                        {currentTrack && <CreatePlayerWave />}
                        <div className={styles['mini-player__duration']}>{parseSeconds(HLSPlayer.getDuration())}</div>
                    </div>
                </div>
                <div className={styles['mini-player__actions']}>
                    <div className={styles['mini-player__volume-action']}>
                        <button
                            className={styles['mini-player__volume-action--volume']}
                            type="button"
                            aria-label="Repeat"
                            onClick={(e) => {
                                e.stopPropagation();
                                setVolume(!volume ? 100 : 0);
                            }}
                        >
                            {volume ? <VolumeIcon /> : <VolumeMuteIcon />}
                        </button>
                        <div className={styles['mini-player__volume-action--slider-container']} ref={volumeSliderRef}>
                            <Slider
                                className={styles['mini-player__volume-action--slider']}
                                min={0}
                                max={100}
                                step={1}
                                value={volume}
                                onChange={setVolume}
                                vertical
                                trackStyle={sliderStyles.createTrackStyle}
                                handleStyle={sliderStyles.handleStyle}
                            />
                        </div>
                    </div>

                    <div className={styles['mini-player__menu-wrapper']}>
                        {/* NOTE(paulomartinsbynd): because these submission tracks don't have a
                         * complete structure [we are just using its title, url and wave]
                         * ID is being assigned as ? so we can prevent 3 dots button rendering
                         * for these submissions */}
                        {currentTrack.id !== '?' && (
                            <ThreeDotsButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openThreeDotsModalSheet({
                                        newCreateActionType: 'mini-player',
                                        shareUrl: createAppRoutes.packsSlug(currentTrack.slug),
                                        actionId: currentTrack.id,
                                        secondaryActionId: currentTrack.slug,
                                        left: e.currentTarget.getBoundingClientRect().left + 20,
                                        top: e.currentTarget.getBoundingClientRect().top + window.scrollY - 120,
                                    });
                                }}
                                hasTooltip />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
