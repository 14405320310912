import { Notification } from '@bpm-web-app/supreme-api-sdk';
import { useCurrentUser, useGetCredits, useGetMeSubscription, useNotifications } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, getLoginRedirectLink, getSignupRedirectLink, localStorageAdd, useOnClickOutside, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Slider from 'rc-slider';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

// Icons
import BpmLogo from '../../assets/icons/bpm-logo.svg';
import CloseIcon from '../../assets/icons/close.svg';
import CreateLogo from '../../assets/icons/create/create-logo-icon.svg';
import DriveIcon from '../../assets/icons/create/my-drive.svg';
import HomeIcon from '../../assets/icons/home.svg';
import NavIcon from '../../assets/icons/nav-icon.svg';
import SearchIcon from '../../assets/icons/search-icon-nav.svg';

import { useCreatePlayer } from '../../../../utils/src/lib/create-player.context';
import ArrowDownTriangle from '../../assets/icons/arrow-down-triangle.svg';
import Filters from '../filters/filters';
import { FiltersContext } from '../filters/filters.context';
import { sliderStyles } from '../mini-player/common';
import { NavContext } from '../nav/nav.context';
import PlaylistsForm, { PlaylistsFormProps } from '../playlists-form/playlists-form';
import PredictiveSearch from '../predictive-search/predictive-search';
import { QualityControlContext, QualityControlToggle, SearchInput } from '../shared';
import { AppLink } from '../shared/app-link/app-link';
import { PlatformSwitch } from '../shared/platform-switch/platform-switch';
import { SquaredButton } from '../shared/squared-button/squared-button';
import { UserNotifications } from '../user-notifications/user-notifications';
import { HeaderAccount } from './header-account/header-account';
import { HeaderFullscreenButton } from './header-fullscreen-button/header-fullscreen-button';
import { HeaderNotificationButton } from './header-notification-button/header-notification-button';
import { HeaderPlatformsButton } from './header-platforms-button/header-platforms-button';
import { HeaderProfileButton } from './header-profile-button/header-profile-button';
import { HeaderVolumeButton } from './header-volume-button/header-volume-button';
import mainHeaderStyles from './supreme-header.module.css';
import { SupremeProfileMenu } from './supreme-profile-menu/supreme-profile-menu';

export function CreateHeader() {
    const { resetFilters } = useContext(FiltersContext);
    const { data: subscriptionData } = useGetMeSubscription('create');

    const { volume, setVolume } = useCreatePlayer();
    const [isVolumeSliderOpen, setIsVolumeSliderOpen] = useState(false);
    const volumeButtonRef = useRef<HTMLDivElement>(null);
    const { ref: volumeSliderRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!volumeSliderRef.current?.contains(e.target as Node) && !volumeButtonRef.current?.contains(e.target as Node)) {
            setIsVolumeSliderOpen(false);
        }
    });
    const { data: userCredits } = useGetCredits();
    const router = useRouter();

    const { data: userData } = useCurrentUser();
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const [showPlatformSwitch, setShowPlatformSwitch] = useState(false);
    const { isNavOpen, openNav, closeNav, isSearchVisible, hideSearchBar, showSearchBar } = useContext(NavContext);

    const { data: notifications } = useNotifications([Notification.PlatformEnum.Create]);
    const [showNotifications, setShowNotifications] = useState(false);
    const { isMobile: isMobileViewPort } = useViewport();
    const { ref: notificationElRef } = useOnClickOutside<HTMLDivElement>(false, () => (isMobileViewPort ? null : setShowNotifications(false)));

    const desktopMenuOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: predictiveSearchRef, showComponent, setShowComponent } = useOnClickOutside(false);
    const { ref: profileMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopMenuOpenerRef.current?.contains(e.target as Node)) {
            setIsProfileMenuOpen(false);
        }
    });

    const desktopSwitchPlatformOpenerRef = useRef<HTMLDivElement>(null);
    const { ref: switchPlatformMenuRef } = useOnClickOutside<HTMLDivElement>(false, (e: MouseEvent) => {
        if (!desktopSwitchPlatformOpenerRef.current?.contains(e.target as Node)) {
            setShowPlatformSwitch(false);
        }
    });

    const [searchTerm, setSearchTerm] = useState('');
    const isAccount = useMemo(() => router.pathname.includes('/account/'), [router.pathname]);

    const hasNotifications = useMemo(() => notifications?.data?.some((notification) => !notification.read), [notifications?.data]);

    const closeIcon = useMemo(() => <CloseIcon />, []);
    const navIcon = useMemo(() => <NavIcon />, []);
    const [openForm, setOpenForm] = useState<null | PlaylistsFormProps>(null);
    const { isAnonymous } = useUserSettings();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const contextMenuForm = () => <PlaylistsForm {...openForm!} />;

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        setSearchTerm((router?.query['searchTerm'] as string) || '');
    }, [router?.query]);

    const handleSearch = (e: React.FormEvent<HTMLElement>) => {
        if (searchTerm.trim()) {
            localStorageAdd('search-history', searchTerm);
            resetFilters();
            router.push({
                pathname: createAppRoutes.search,
                query: { searchTerm },
            });
        }
        e.preventDefault();
    };

    useEffect(() => {
        setIsProfileMenuOpen(false);
    }, [router]);

    const handleMenuToggle = () => {
        if (isMobileViewPort) {
            closeNav();
        }
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    const searchForm = (isMobile = false) => {
        if (isMobile) {
            return (
                <form className={mainHeaderStyles['supreme-header--legacy-mobile__search']}>
                    {/* @TODO setup react hook form */}
                    <div className={mainHeaderStyles['supreme-header--legacy-mobile__search-container-input']}>
                        {!!searchTerm && (
                            <>
                                <Filters platform="create" showOnMobile inlineMobileStyle />
                                <button
                                    onClick={() => {
                                        hideSearchBar();
                                        setSearchTerm('');
                                    }}
                                    type="button"
                                    aria-label="Clear Search"
                                    className={mainHeaderStyles['supreme-header--legacy-mobile__search-clear']}
                                >
                                    <i className="icon icon--controls-clear" />
                                </button>
                            </>
                        )}
                    </div>
                </form>
            );
        }
        return (
            <div ref={predictiveSearchRef}>
                <form className={mainHeaderStyles['supreme-header__search']} onSubmit={handleSearch}>
                    <SearchInput
                        placeholder="Search packs, sounds and more."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        onClear={() => setSearchTerm('')}
                        variant="large"
                        onFocus={() => setShowComponent(true)}
                        isAlternateMobileLayout
                        isActive={showComponent}
                    />
                    <PredictiveSearch term={searchTerm} setTerm={setSearchTerm} setOpenForm={setOpenForm} handleCancel={() => setShowComponent(false)} isVisible={showComponent && searchTerm === ''} />
                </form>
            </div>
        );
    };
    return (
        <div className={mainHeaderStyles['supreme-header']}>
            {openForm && contextMenuForm()}
            <header className={classNames(mainHeaderStyles['supreme-header__container'], { [mainHeaderStyles['supreme-header__container--nav-opened']]: isNavOpen })}>
                <button
                    type="button"
                    aria-label="Open Nav"
                    onClick={() => {
                        if (isNavOpen) {
                            setShowNotifications(false);
                            closeNav();
                        } else {
                            openNav();
                        }
                    }}
                    className={mainHeaderStyles['supreme-header__mobile-nav-btn']}
                >
                    {isNavOpen ? closeIcon : navIcon}
                </button>
                {isMobileViewPort ? (
                    <div className={mainHeaderStyles['supreme-header__logo']}>
                        <AppLink href="/">
                            <a aria-label="Homepage" className={mainHeaderStyles['supreme-header__logo-img']}>
                                <BpmLogo />
                            </a>
                        </AppLink>
                    </div>
                ) : null}

                {isAccount ? <HeaderAccount IconComponent={CreateLogo} title="BPM Create" /> : <div className={mainHeaderStyles['supreme-header__center']}>{searchForm()}</div>}

                <div
                    className={classNames(mainHeaderStyles['supreme-header__right'], { [mainHeaderStyles['supreme-header__right--with-action']]: !subscriptionData?.data?.membership.has_membership })}
                >
                    {!isAccount && (
                        <>
                            <HeaderFullscreenButton />
                            <div>
                                <div ref={desktopSwitchPlatformOpenerRef}>
                                    <HeaderPlatformsButton onClick={() => setShowPlatformSwitch(!showPlatformSwitch)} isOpen={showPlatformSwitch} />
                                </div>
                                <div ref={switchPlatformMenuRef}>
                                    {showPlatformSwitch ? (
                                        <PlatformSwitch
                                            platforms={[{ name: 'BPM Supreme' }, { name: 'BPM Artist' }]}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className={mainHeaderStyles['supreme-header__create-volume-action']}>
                                <div ref={volumeButtonRef}>
                                    <HeaderVolumeButton
                                        isOpen={isVolumeSliderOpen}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (!isVolumeSliderOpen) {
                                                setIsVolumeSliderOpen(true);
                                                return;
                                            }
                                            setVolume(!volume ? 100 : 0);
                                        }}
                                        volume={volume}
                                    />
                                </div>
                                <div
                                    className={classNames(mainHeaderStyles['supreme-header__create-volume-action-slider-container'], {
                                        [mainHeaderStyles['supreme-header__create-volume-action-slider-container--open']]: isVolumeSliderOpen,
                                    })}
                                    ref={volumeSliderRef}
                                >
                                    <Slider
                                        className={mainHeaderStyles['supreme-header__create-volume-action-slider']}
                                        min={0}
                                        max={100}
                                        step={1}
                                        value={volume}
                                        onChange={setVolume}
                                        vertical
                                        trackStyle={sliderStyles.createTrackStyle}
                                        handleStyle={sliderStyles.handleStyle}
                                    />
                                </div>
                            </div>
                            <QualityControlContext.Consumer>
                                {(value) => {
                                    if (isAnonymous) return null;
                                    return <QualityControlToggle />;
                                }}
                            </QualityControlContext.Consumer>
                            {isAnonymous ? (
                                <div className={mainHeaderStyles['supreme-header__anonymous-buttons']}>
                                    <button type="button" onClick={() => router.replace(getLoginRedirectLink())}>
                                        <div>Log In</div>
                                    </button>
                                    <SquaredButton type="dynamic" label="Start Downloading" onPress={() => router.push('/account/plan')} />
                                </div>
                            ) : (
                                <div ref={notificationElRef}>
                                    <HeaderNotificationButton
                                        onClick={() => setShowNotifications((prevState) => !prevState)}
                                        hasNotifications={hasNotifications}
                                        isShowingNotifications={showNotifications}
                                    />
                                    <UserNotifications
                                        platform={Notification.PlatformEnum.Create}
                                        onClose={() => {
                                            setShowNotifications(false);
                                        }}
                                        isVisible={showNotifications && !isMobileViewPort}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {userCredits && !isAnonymous && (
                        <div className={mainHeaderStyles['supreme-header__credits']}>
                            <div className={classNames(mainHeaderStyles['supreme-header__credits-btn'])}>
                                <span>Credits</span>
                                <span>{userCredits.regular + userCredits.promo}</span>
                            </div>
                        </div>
                    )}
                    <div ref={desktopMenuOpenerRef}>
                        <HeaderProfileButton
                            isOpen={isProfileMenuOpen}
                            isHidden={isAnonymous}
                            imageAlt={userData?.data?.user?.full_name}
                            imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                            onClick={handleMenuToggle}
                        />
                    </div>
                    {!isAnonymous && !subscriptionData?.data?.membership.has_membership && (
                        <div className={mainHeaderStyles['supreme-header__subscribe']}>
                            <SquaredButton type="dynamic" label="Subscribe" onPress={() => router.replace('/account/plan')} />
                        </div>
                    )}
                </div>
                {isAnonymous ? (
                    <button type="button" aria-label="Open Nav" className={mainHeaderStyles['supreme-header__mobile-profile-btn']} onClick={() => router.replace(getSignupRedirectLink())}>
                        Sign Up
                    </button>
                ) : (
                    <div className={mainHeaderStyles['supreme-header__mobile-right']}>
                        {!isAnonymous && !subscriptionData?.data?.membership.has_membership && (
                            <div className={mainHeaderStyles['supreme-header__subscribe-mobile']}>
                                <button type="button" aria-label="Subscribe" className={mainHeaderStyles['supreme-header__mobile-subscribe-btn']} onClick={() => router.replace('/account/plan')}>
                                    Subscribe
                                </button>
                            </div>
                        )}

                        <HeaderProfileButton
                            imageAlt={userData?.data?.user?.full_name}
                            imageUrl={userData?.data?.user?.profile_image_thumbnail_url}
                            onClick={handleMenuToggle}
                            isMobile
                            isOpen={isProfileMenuOpen}
                            hasNotifications={hasNotifications}
                        />
                    </div>
                )}
            </header>
            <div className={mainHeaderStyles['supreme-header__mobile']}>
                <AppLink href="/">
                    <a aria-label="Home" className={mainHeaderStyles['supreme-header__mobile-btn']}>
                        <button type="button" aria-label="Home" onClick={() => hideSearchBar()}>
                            <HomeIcon />
                        </button>
                    </a>
                </AppLink>
                <button type="button" aria-label="Search" className={mainHeaderStyles['supreme-header__mobile-btn']} onClick={() => (isSearchVisible ? hideSearchBar() : showSearchBar())}>
                    <SearchIcon />
                </button>
                <AppLink href={createAppRoutes.myDrive}>
                    <a aria-label="My Drive" className={mainHeaderStyles['supreme-header__mobile-btn']}>
                        <button type="button" aria-label="My Drive" onClick={() => hideSearchBar()}>
                            <DriveIcon />
                        </button>
                    </a>
                </AppLink>

            </div>
            <SupremeProfileMenu
                isOpen={isProfileMenuOpen}
                ref={profileMenuRef}
                platforms={[{ name: 'BPM Supreme' }, { name: 'BPM Artist' }]}
                setIsOpen={setIsProfileMenuOpen}
                userData={userData}
            />

            {isSearchVisible && (
                <div className={mainHeaderStyles['supreme-header__mobile-search']}>
                    {!isMobileViewPort && (
                        <button
                            type="button"
                            aria-label="Open Nav"
                            onClick={() => {
                                if (isNavOpen) {
                                    setShowNotifications(false);
                                    closeNav();
                                } else {
                                    openNav();
                                }
                            }}
                            className={mainHeaderStyles['supreme-header__mobile-nav-btn']}
                        >
                            {isNavOpen ? closeIcon : navIcon}
                        </button>
                    )}
                    <div className={mainHeaderStyles['supreme-header__mobile-search-form']}>{searchForm(true)}</div>
                </div>
            )}
        </div>
    );
}

export default CreateHeader;
