import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './card-image-overlay.module.css';
import { CustomIcon } from '../../custom-icon/custom-icon';
import { ActionType } from '../../three-dots-sheet/three-dots-sheet.context';
import { CreateActionType } from '../../three-dots-sheet/create-three-dots-sheet.context';

export type CreateCardContentTypeWithoutActions = 'news' | 'contest';
interface CardImageOverlayProps {
    contentType?: ActionType | CreateActionType | CreateCardContentTypeWithoutActions;
    isVisible?: boolean;
    isPlaying?: boolean;
    isPlayable?: boolean;
    isFavorite?: boolean;
    onPlayPressed?: () => void;
    onFollowPressed?: () => void;
    onSavePressed?: () => void;
    onDownloadPressed?: (e: React.MouseEvent<HTMLElement>) => void;
    onPremiumOnlyAccessPressed?: () => void;
    onEditPressed?: () => void;
    isPremiumOnly?: boolean;
    hasPremiumOnlyAccess?: boolean;
    isCreate?: boolean;
    hideButtons?: boolean;
}

type OnPressFunctionType = (e: React.MouseEvent<HTMLElement>) => void | undefined;

export function CardImageOverlay({
    contentType,
    isVisible,
    isPlaying,
    isPlayable,
    isFavorite,
    onDownloadPressed,
    onFollowPressed,
    onPlayPressed,
    onSavePressed,
    onPremiumOnlyAccessPressed,
    onEditPressed,
    isPremiumOnly,
    hasPremiumOnlyAccess,
    isCreate,
    hideButtons
}: CardImageOverlayProps) {
    const handlePressed = (e: React.MouseEvent<HTMLElement>, onPressFunction?: OnPressFunctionType) => {
        e.stopPropagation();
        e.preventDefault();

        if (onPressFunction) onPressFunction(e);
    };

    const renderButtons = useMemo(() => {
        if (hideButtons) return null;
        switch (contentType) {
            case 'download:album':
            case 'stream:album':
            case 'download:track':
            case 'user-playlist-track':
            case 'curated-set':
            case 'pack':
            case 'sound':
            case 'track':
                return (
                    <>
                        {onSavePressed ? (
                            <button
                                type="button"
                                onClick={(e) => handlePressed(e, onSavePressed)}
                            >
                                <CustomIcon hasBackgroundBorderHover hasActiveState type={isFavorite ? 'heart-filled-icon' : 'heart-outline-icon'} onClick={(e) => handlePressed(e, onSavePressed)} hasBackgroundHover color="white" tooltip={isFavorite ? 'Remove' : 'Save'} tooltipBottomOffset={7} container="round" containerSize={32} />
                            </button>
                        ) : null}

                        {onDownloadPressed && (isPremiumOnly === false || hasPremiumOnlyAccess === true) ? (
                            <CustomIcon hasBackgroundBorderHover hasActiveState type="download-icon" onClick={(e) => handlePressed(e, onDownloadPressed)} hasBackgroundHover color="white" tooltip="Download" tooltipBottomOffset={7} container="round" containerSize={32} />
                        ) : null}

                        {onDownloadPressed && isPremiumOnly === true && hasPremiumOnlyAccess === false ? (
                            <CustomIcon
                                hasBackgroundBorderHover
                                hasActiveState
                                type="lock-premium-icon"
                                onClick={(e) => handlePressed(e, onPremiumOnlyAccessPressed)}
                                hasBackgroundHover
                                color="white"
                                tooltip="Upgrade to Unlock"
                                tooltipBottomOffset={7}
                                container="round"
                                containerSize={32} />
                        ) : null}
                        {onDownloadPressed && isCreate === true ? (
                            <CustomIcon hasBackgroundBorderHover hasActiveState type="download-icon" onClick={(e) => handlePressed(e, onDownloadPressed)} hasBackgroundHover color="white" tooltip="Download" tooltipBottomOffset={7} container="round" containerSize={32} />
                        ) : null}
                    </>
                );
            case 'drive':
                return (
                    <>
                        {onEditPressed && isCreate === true ? (
                            <CustomIcon hasBackgroundBorderHover hasActiveState type="edit-pencil-icon" onClick={(e) => handlePressed(e, onEditPressed)} hasBackgroundHover color="white" tooltip="Edit" tooltipBottomOffset={7} container="round" containerSize={32} />
                        ) : null}

                        {onDownloadPressed && isCreate === true ? (
                            <CustomIcon hasBackgroundBorderHover hasActiveState type="download-icon" onClick={(e) => handlePressed(e, onDownloadPressed)} hasBackgroundHover color="white" tooltip="Download" tooltipBottomOffset={7} container="round" containerSize={32} />
                        ) : null}
                    </>
                );
            case 'curatedSet':
            case 'exclusive-playlist':
                return (onFollowPressed ? (
                    <button
                        type="button"
                        onClick={(e) => handlePressed(e, onFollowPressed)}
                    >
                        <CustomIcon hasBackgroundBorderHover hasActiveState type={isFavorite ? 'checkmark' : 'plus-icon'} onClick={(e) => handlePressed(e, onFollowPressed)} hasBackgroundHover color="white" tooltip={isFavorite ? 'Unfollow' : 'Follow'} tooltipBottomOffset={7} container="round" containerSize={32} />
                    </button>
                ) : null);
            case 'label':
                return null;
            default:
                return null;
        }
    }, [contentType, hasPremiumOnlyAccess, hideButtons, isCreate, isFavorite, isPremiumOnly, onDownloadPressed, onEditPressed, onFollowPressed, onPremiumOnlyAccessPressed, onSavePressed]);

    if (contentType === 'label' && isCreate) return null;
    return (
        <div className={classNames(styles['card-image-overlay'], { [styles['card-image-overlay--visible']]: isVisible })}>
            {isPlayable ? (
                <>
                    {onPlayPressed ? (
                        <button
                            type="button"
                            onClick={(e) => handlePressed(e, onPlayPressed)}
                        >
                            <CustomIcon hasActiveState type={isPlaying ? 'pause-button-round-icon' : 'play-button-round-icon'} size={40} color="white" hasIconHover />
                        </button>
                    ) : null}

                    <div className={styles['card-image-overlay__buttons']}>
                        {renderButtons}
                    </div>
                </>
            ) : (
                <>
                    <div />
                    <div className={styles['card-image-overlay__buttons']}>
                        {renderButtons}
                    </div>
                </>
            )}
        </div>
    );
}
