import { MouseEvent } from 'react';
import { SignUpModalProps, useHubSwitch, useUserSettings } from '@bpm-web-app/utils';
import { SquaredButton } from '../squared-button/squared-button';
import BPMIcons from '../bpm-icons/bpm-icons';

type FollowButtonType = 'artist' | 'playlist';

interface FollowButtonProps {
    type: FollowButtonType;
    isFollowed: boolean;
    handleFollow?: (e: MouseEvent<HTMLElement>) => void;
    removeHover?: boolean;
    fullWidth?: boolean;
    signUpModalType?: SignUpModalProps['type'];
    filled?: boolean
}

export function FollowButton({ type = 'artist', isFollowed, handleFollow, removeHover = false, fullWidth, signUpModalType = 'playlist', filled }: FollowButtonProps) {
    const { isAnonymous, setShowSignUpModal } = useUserSettings();
    const { isCreate } = useHubSwitch();

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isAnonymous) {
            setShowSignUpModal({ type: isCreate ? 'pack' : signUpModalType });
            return;
        }
        if (handleFollow) handleFollow(e);
    };

    switch (type) {
        case 'playlist':
            return <SquaredButton
                type={isFollowed ? 'outline-active' : filled ? 'filled' : 'outline'}
                svgColoring={isFollowed ? 'stroke' : 'fill'}
                leftIcon={isFollowed ? <BPMIcons.CheckmarkDefaultIcon /> : <BPMIcons.PlusIcon />}
                label={isFollowed ? 'Following' : 'Follow'}
                onPress={handleOnClick}
                removeHover={removeHover}
                fullWidth={fullWidth} />;
        case 'artist':
            return <SquaredButton
                type={isFollowed ? 'outline-active' : filled ? 'filled' : 'outline'}
                svgColoring="fill"
                leftIcon={isFollowed ? <BPMIcons.FollowActiveIcon /> : <BPMIcons.PlusIcon />}
                label={isFollowed ? 'Following' : 'Follow'}
                onPress={handleOnClick}
                removeHover={removeHover}
                fullWidth={fullWidth} />;
        default:
            return null;
    }
}
