import classNames from 'classnames';
import { CreateCardCarouselItem, SignUpModalMediaTypes, getPlatformLinkUsingRouter, getSignupRedirectLinkAccountPlans, useUserSettings, getPlatformLinkFromHub, useHubSwitch } from '@bpm-web-app/utils';
import { Album, CuratedSet, Playlist } from '@bpm-web-app/download-api-sdk';
import { useRouter } from 'next/router';
import { Preset } from '@bpm-web-app/create-api-sdk';
import { SubscriptionValueProposition } from '../../account/account-plan/subscription-item/subsciption-value-proposition';
import { ActionModal } from '../action-modal/action-modal';
import styles from './sign-up-modal.module.css';
import { SquaredButton } from '../squared-button/squared-button';
import { BreakpointView } from '../ui';

const VALUE_PROPOSITIONS_TEXTS = ['New Music Added Daily', 'Exclusive Track Versions, Remixes, and Edits', 'Expert Curation from Industry-Leading Talent', 'Advanced Search Made For DJs'];
const VALUE_PROPOSITIONS_TEXTS_UPGRADE = ['Exclusive Playlists for all Occasions', 'Curated Sets from Top DJs', 'Collaborative Playlists', 'Weekly Premium Exclusives'];
const VALUE_PROPOSITIONS_TEXTS_CREATE = ['Diverse Sample Library Across Every Genre', 'Affordable plans starting at $0.99/m', 'Powerful Search Functionality'];

export function SignUpModal({ platform }: { platform: 'supreme' | 'create' }) {
    const router = useRouter();
    const { hub } = useHubSwitch();
    const isCreate = platform === 'create';

    const PLACEHOLDER_LEFT_IMAGE = isCreate ? 'https://media.create.bpmsupreme.com/artwork/7e6cde48-a35c-4b88-b055-7d308b9f9630/acc1fc9a-e410-4484-b2bc-42c22a5767d8.jpg' : 'https://av.bpmsupreme.com/covers/f851088f-de27-46dd-9a53-896f8a99dfd0.jpg';
    const PLACEHOLDER_RIGHT_IMAGE = isCreate ? 'https://media.create.bpmsupreme.com/artwork/d8be6456-494d-451e-8225-3cdd97250134/c594cb1a-4040-4de0-9a0f-32585889adc5.jpg' : 'https://av.bpmsupreme.com/covers/15c3dd2c-9144-4777-b1c1-092adab0363b.jpg';
    const PLACEHOLDER_CENTER_IMAGE = isCreate ? 'https://media.create.bpmsupreme.com/artwork/1f105972-c86b-42da-ad7f-0a8416951f6e/273bcc26-5828-4728-b046-7fb63c2e8ba4.jpg' : 'https://av.bpmsupreme.com/covers/9c4ca7ee-ff6e-4af5-a8ef-5cb7fcb6a192.jpg';

    const {
        isShowingSignUpModal: isOpen,
        actionModalProps,
        modalType: type,
        closeSignUpModal: close,
        mediaLeft,
        mediaCenter,
        mediaRight,
        mediaMostLeft,
        mediaMostRight,
        isAnonymous
    } = useUserSettings();

    const getImageURL = (media: SignUpModalMediaTypes | undefined, placeholderUrl: string) => {
        switch (type) {
            case 'track':
                if (media && (media as any).image_url) return (media as any).image_url;
                //  Specific for Labels
                if (media && (media as any).logo_url) return (media as any).logo_url;
                // Specific for packs
                if (media && (media as any).artwork_url) return (media as any).artwork_url;
                return media && media as Album && (media as Album).cover_url ? (media as Album).cover_url : placeholderUrl;
            case 'playlist':
                return media && media as Playlist && (media as Playlist).image_url ? (media as Playlist).image_url : placeholderUrl;
            case 'preset':
                return media && media as Preset && (media as Preset).artwork_url ? (media as Preset).artwork_url : placeholderUrl;
            case 'pack':
            case 'sound':
                if (media && (media as CreateCardCarouselItem) && (media as CreateCardCarouselItem).coverUrl) return (media as CreateCardCarouselItem).coverUrl;
                //  Specific for Card Create in Grids
                if (media && (media as any).image_url) return (media as any).image_url;
                //  Specific for Labels
                if (media && (media as any).logo_url) return (media as any).logo_url;
                // Specific for packs
                if (media && (media as any).artwork_url) return (media as any).artwork_url;
                return placeholderUrl;
            case 'curated-set':
                return media && media as CuratedSet && (media as CuratedSet).image_url ? (media as CuratedSet).image_url : placeholderUrl;
            default:
                return placeholderUrl;
        }
    };

    const renderImages = () => {
        const mostLeftImage = getImageURL(mediaMostLeft, PLACEHOLDER_LEFT_IMAGE);
        const leftImage = getImageURL(mediaLeft, PLACEHOLDER_LEFT_IMAGE);
        const rightImage = getImageURL(mediaRight, PLACEHOLDER_RIGHT_IMAGE);
        const centerImage = getImageURL(mediaCenter, PLACEHOLDER_CENTER_IMAGE);
        const mostRightImage = getImageURL(mediaMostRight, PLACEHOLDER_RIGHT_IMAGE);

        return (
            <div className={styles['sign-up-modal__images']}>
                <img alt="album-left" className={classNames(styles['sign-up-modal__images--container'], styles['sign-up-modal__images--most-left'])} src={mostLeftImage} />
                <img alt="album-left" className={classNames(styles['sign-up-modal__images--container'], styles['sign-up-modal__images--left'])} src={leftImage} />
                <img alt="album-center" className={classNames(styles['sign-up-modal__images--container'], styles['sign-up-modal__images--center'])} src={centerImage} />
                <img alt="album-right" className={classNames(styles['sign-up-modal__images--container'], styles['sign-up-modal__images--right'])} src={rightImage} />
                <img alt="album-right" className={classNames(styles['sign-up-modal__images--container'], styles['sign-up-modal__images--most-right'])} src={mostRightImage} />
            </div>
        );
    };

    const currentTitle = () => {
        switch (type) {
            case 'full-access':
                return 'For Full Access';
            case 'curated-set':
                return 'To Access this Curated Set';
            case 'playlist':
                return 'To Access this Playlist';
            case 'track':
                return 'To Download this Track';
            case 'sound':
                return 'To Download this Sound';
            case 'preset':
                return 'To Follow this Preset';
            case 'pack':
                return 'To Download this Pack';
            default:
                return '';
        }
    };

    const shouldShowPremiumUpgradeModal = () => {
        if (mediaCenter && mediaCenter as Album && (mediaCenter as Album).is_premium_only === true && (mediaCenter as Album).has_premium_only_access === false) {
            return true;
        }
        return false;
    };

    const renderValuePropositions = () => {
        const propositions = isCreate ? VALUE_PROPOSITIONS_TEXTS_CREATE : shouldShowPremiumUpgradeModal() ? VALUE_PROPOSITIONS_TEXTS_UPGRADE : VALUE_PROPOSITIONS_TEXTS;
        return (
            <div className={styles['sign-up-modal__value']}>
                {propositions.map((text) => <SubscriptionValueProposition label={text} key={text} isAvailable textClassname={styles['sign-up-modal__value--text']} />)}
            </div>
        );
    };

    const onGetStartedPressed = () => {
        if (shouldShowPremiumUpgradeModal() && !isAnonymous) {
            if (isCreate) router.replace('/account/plan');
            else router.replace(getPlatformLinkUsingRouter('/account/plan'));
            close();
        } else if (shouldShowPremiumUpgradeModal() && isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        } else {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            router.push(getPlatformLinkFromHub(hub, `/account/plan?library=${router.query['library']?.toString() || 'music'}`));
            close();
        }
    };

    return (
        <ActionModal
            headerTitle="Get Started"
            isOpen={isOpen}
            onClose={() => close()}
            variant="dark"
            contentClassname={styles['sign-up-modal__action-modal']}
            hideCancel
            shouldCloseOnOverlayClick
            {...actionModalProps}
        >
            <div className={styles['sign-up-modal']}>
                {renderImages()}
                <div className={styles['sign-up-modal__text-container']}>
                    <div className={styles['sign-up-modal__title']}>{shouldShowPremiumUpgradeModal() ? <>Upgrade to Download <br />Premium Exclusives</> : `Sign Up ${currentTitle()}`}</div>
                    {shouldShowPremiumUpgradeModal() ? (
                        <div className={styles['sign-up-modal__subtitle']}>Premium subscribers get additional weekly exclusives on top of other premium features, upgrade now and gain immediate access.</div>
                    ) : isCreate ? (
                        <div className={styles['sign-up-modal__subtitle']}>And start downloading samples, MIDI, synth presets and more thousands of soundpacks.</div>
                    ) : (
                        <div className={styles['sign-up-modal__subtitle']}>And start downloading DJ-Ready music from over 100 partner record labels.</div>
                    )}
                </div>
                <div className={styles['sign-up-modal__divider']} />
                {renderValuePropositions()}
                <BreakpointView
                    desktopChildren={
                        <div className={styles['sign-up-modal__buttons-container']}>
                            <SquaredButton type="outline" label="Not Now" onPress={() => close()} />
                            <SquaredButton type="filled" label={shouldShowPremiumUpgradeModal() ? 'Upgrade Now' : 'Get Started'} onPress={onGetStartedPressed} />
                        </div>
                    }
                    mobileChildren={
                        <div className={styles['sign-up-modal__buttons-container']}>
                            <SquaredButton type="outline" label="Not Now" onPress={() => close()} fullWidth />
                            <SquaredButton type="filled" label={shouldShowPremiumUpgradeModal() ? 'Upgrade Now' : 'Get Started'} onPress={onGetStartedPressed} fullWidth />
                        </div>
                    } />
            </div>
        </ActionModal>
    );
}
