import { CreateCardCarouselItem, isHexColorLight, State, usePlayerState, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { CuratedSet, Genre, Label, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './card.module.css';
import { CreateActionType, CreateThreeDotsSheetContext } from '../three-dots-sheet/create-three-dots-sheet.context';
import { AppLink } from '../app-link/app-link';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import { CardCustomPreset } from './card';
import { Accepting, DragDropItem, DragResult } from '../../droppable/droppable';
import { ReactComponent as Top } from '../../../assets/icons/top-badge.svg';
import { ReactComponent as Trending } from '../../../assets/icons/trending-badge.svg';
import { ReactComponent as ExclusiveSoundPacks } from '../../../assets/icons/exclusive-soundpacks-badge.svg';
import { ReactComponent as StaffPick } from '../../../assets/icons/staffpick-badge.svg';
import { CardImageOverlay, CreateCardContentTypeWithoutActions } from './card-image-overlay/card-image-overlay';
import { CardImage } from './card-image/card-image';
import { CardDescription } from './card-description/card-description';
import ThreeDotsIconSmall from '../../../assets/icons/more-dots-vert-small.svg';
import { CustomIcon } from '../custom-icon/custom-icon';

export type CreateCardSize = 'small' | 'medium' | 'large' | 'large-square' | 'user-playlist';

export type CreateCardItemType = SoundPackage | CuratedSet | CreateCardCarouselItem | Label;

const contentTypesWithoutImgOverlay: CreateCardProps['contentType'][] = ['news', 'contest'];
export const showActionsForContentType = (contentType?: CreateActionType | CreateCardContentTypeWithoutActions) => !contentTypesWithoutImgOverlay.includes(contentType as CreateActionType);

export interface CreateCardProps {
    contentType: CreateActionType | CreateCardContentTypeWithoutActions;
    title: string;
    id: string;
    label?: string;
    link?: string;
    cardSize: CreateCardSize;
    description?: Genre | ReactNode;
    tag?: ReactNode;
    onFavorite?: (id: string, onlyLike?: boolean) => void;
    isFavorite?: boolean;
    onCardPlay?: (id: string) => void;
    imageUrl: string;
    approved?: boolean
    // NOTE: Are both images needed ? Can this be abstracted or omited ?
    imageUrl2x?: string;
    isDescriptionSingleLine?: boolean;
    openInNewTab?: boolean;
    userPlaylistConfig?: {
        initials: string;
        imageBgColor: string;
    };
    hasExtraDemo?: boolean;
    preset?: CardCustomPreset;
    shareURL?: string;
    badge?: SoundPackage.BadgeEnum;
    hideMoreOptions?: boolean,
    items?: CreateCardItemType[];
    onDownload?: (id: string) => void;
    onEdit?: (id: string) => void;
}

export function CardCreate({
    contentType,
    cardSize,
    approved,
    id,
    title,
    imageUrl,
    imageUrl2x,
    label,
    link,
    description,
    tag,
    openInNewTab,
    isFavorite,
    onFavorite,
    onCardPlay,
    onEdit,
    userPlaylistConfig,
    isDescriptionSingleLine = false,
    hasExtraDemo = false,
    preset,
    shareURL,
    hideMoreOptions = false,
    badge,
    items,
    onDownload
}: CreateCardProps) {
    const isCardPlayable = useMemo(() => !!onCardPlay, [onCardPlay]);
    const isImgOverlayAvailable = useMemo(() => showActionsForContentType(contentType), [contentType]);
    const { currentTrack, togglePlayPause } = useCreatePlayer();
    const [isCardImageLoaded, setIsCardImageLoaded] = useState(false);
    const { isAnonymous, setSelectedMedia, setShowSignUpModal } = useUserSettings();
    const [isHoveringCard, setIsHoveringCard] = useState(false);

    const playerState = usePlayerState();

    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);

    const setCurrentMediaInContext = useCallback((showSignUpModal?: boolean) => {
        if (isAnonymous && items) {
            const currentCenterMedia = items.find((item) => item.id === id);
            if (currentCenterMedia) setSelectedMedia(currentCenterMedia, items);

            if (showSignUpModal) {
                switch (contentType) {
                    case 'pack':
                        setShowSignUpModal({ type: 'pack' });
                        break;
                    default:
                        setShowSignUpModal({ type: 'pack' });
                        break;
                }
            }
        }
    }, [contentType, id, isAnonymous, items, setSelectedMedia, setShowSignUpModal]);

    const handleFavorite = useCallback((onlyLike?: boolean) => {
        if (isAnonymous) {
            setCurrentMediaInContext(true);
        }
        onFavorite?.(`${id}`, onlyLike);
    }, [id, isAnonymous, onFavorite, setCurrentMediaInContext]);

    const openThreeDots = useCallback(
        async (element) => {
            element.preventDefault();
            if (isAnonymous) {
                setCurrentMediaInContext(true);
                return;
            }
            const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
            openThreeDotsModalSheet({
                newCreateActionType: contentType as CreateActionType,
                actionId: id,
                left,
                top: top + window.scrollY,
                showExtraPlayDemoOption: hasExtraDemo,
                actionsToHide: isCardPlayable ? [] : ['play-demo', 'play-demo-2'],
                shareUrl: shareURL,
            });
        },
        [isAnonymous, openThreeDotsModalSheet, contentType, id, hasExtraDemo, isCardPlayable, shareURL, setCurrentMediaInContext]
    );

    const handleCardPlay = useCallback(() => {
        if (isAnonymous) {
            setCurrentMediaInContext();
        }

        if (currentTrack?.id === id) {
            togglePlayPause();
        } else {
            onCardPlay?.(id);
        }
    }, [currentTrack?.id, id, isAnonymous, onCardPlay, setCurrentMediaInContext, togglePlayPause]);

    const dragAndDropType: Accepting | undefined = useMemo(() => {
        switch (contentType) {
            case 'label':
                return 'Label';
            case 'pack':
                return 'SoundPack';
            case 'curated-set':
                return 'CuratedSet';
            default:
                return 'None';
        }
    }, [contentType]);

    const dragAndDropItem: DragDropItem = useMemo(() => {
        switch (contentType) {
            default:
                return {
                    id, title
                };
        }
    }, [contentType, id, title]);

    const renderBadge = useCallback(() => {
        if (cardSize === 'large-square') {
            switch (badge) {
                case SoundPackage.BadgeEnum.Top:
                    return <Top height={30} scale={3} width={61.25} />;
                case SoundPackage.BadgeEnum.Trending:
                    return <Trending height={30} width={93.75} />;
                case SoundPackage.BadgeEnum.Exclusive:
                    return <ExclusiveSoundPacks height={30} width={97.5} />;
                case SoundPackage.BadgeEnum.StaffPick:
                    return <StaffPick height={30} width={100} />;
                default:
                    return null;
            }
        }
        switch (badge) {
            case SoundPackage.BadgeEnum.Top:
                return <Top />;
            case SoundPackage.BadgeEnum.Trending:
                return <Trending />;
            case SoundPackage.BadgeEnum.Exclusive:
                return <ExclusiveSoundPacks />;
            case SoundPackage.BadgeEnum.StaffPick:
                return <StaffPick />;
            default:
                return null;
        }
    }, [badge, cardSize]);

    const { isMobile } = useViewport();

    // eslint-disable-next-line no-empty-pattern
    const [{ }, dragRef, connectDragPreview] = useDrag<DragDropItem, any, { opacity: number }>({
        item: dragAndDropItem,
        canDrag() {
            return !isMobile;
        },
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { target } = monitor.getDropResult() as DragResult;
                if (target === 'favorites') {
                    handleFavorite(true);
                }
            }
        },
        type: dragAndDropType
    });

    connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true,
    });

    const renderCard = () => {
        return (
            <div
                ref={dragRef}
                className={classNames(
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    styles['card'],
                    styles[`card--${cardSize}`],
                    {
                        [styles['card--overlay-available']]: isImgOverlayAvailable,
                        [styles['card--qc']]: approved === false,
                        [styles['card--plain-link']]: !isImgOverlayAvailable,
                        [styles[`card--custom-preset-${preset}`]]: !!preset
                    }
                )}
                onMouseEnter={() => setIsHoveringCard(true)}
                onMouseLeave={() => setIsHoveringCard(false)}
            >
                <div
                    className={classNames(styles['card__img-container'], {
                        [styles['card__img-container--small-img']]: userPlaylistConfig,
                        [styles['card__img-container--no-img']]: !imageUrl,
                        [styles['card__img-container--round-image']]: contentType === 'label',
                    })}
                    style={userPlaylistConfig && { backgroundColor: `${userPlaylistConfig.imageBgColor}E6` }}
                >
                    {tag}
                    <CardImage
                        imageUrl={imageUrl}
                        imageUrl2x={imageUrl2x}
                        setIsCardImageLoaded={() => setIsCardImageLoaded(true)}
                        title={title}
                        isSmallImg={!!userPlaylistConfig}
                    />
                    {badge && isCardImageLoaded ? (
                        <div className={classNames(styles['card__badge-container'], styles[`card__badge-container--${cardSize}`], { [styles['card__badge--hidden']]: isHoveringCard })}>
                            <div className={styles['card__badge']}>
                                {renderBadge()}
                            </div>
                        </div>
                    ) : null}

                    <CardImageOverlay
                        isVisible={isHoveringCard}
                        isPlaying={currentTrack?.id === id && playerState === State.Playing}
                        onPlayPressed={handleCardPlay}
                        isPlayable={isCardPlayable}
                        onSavePressed={() => handleFavorite()}
                        onFollowPressed={() => handleFavorite()}
                        onDownloadPressed={() => onDownload ? onDownload(id) : null}
                        contentType={id === 'shared-playlist-drive' ? 'label' : contentType}
                        isFavorite={isFavorite}
                        onEditPressed={() => onEdit ? onEdit(id) : null}
                        hideButtons={hideMoreOptions}
                        isCreate
                    />
                    {label && <span className={styles['card__label']}>{label}</span>}
                    {userPlaylistConfig && (
                        <span
                            className={classNames(styles['card__user-playlist-initials'], {
                                [styles['card__user-playlist-initials--black-text']]: isHexColorLight(userPlaylistConfig.imageBgColor),
                            })}
                        >
                            {userPlaylistConfig.initials}
                        </span>
                    )}
                </div>
                <div className={styles['card__text-container']}>
                    <div className={styles['card__title-container']}>
                        <div className={styles['card__title']}>{title}</div>
                        {contentType !== undefined && contentType !== 'label' && id !== 'shared-playlist-drive' && !hideMoreOptions && (
                            <button
                                aria-label="More Options"
                                type="button"
                                onClick={openThreeDots}
                                className={classNames(styles['card__download-more-dots-container'], styles['card__more-dots-toggler'], styles['card__more-dots-toggler-small'], 'relative-link', { [styles['card__download-more-dots-container--visible']]: isHoveringCard })}
                                data-tip="More"
                                data-offset={'{\'bottom\': 5}'}
                            >
                                <ThreeDotsIconSmall />
                            </button>
                        )}

                        {contentType === 'label' && (
                            <button
                                type="button"
                                className={classNames(styles['card__follow-button-container'], { [styles['card__follow-button-container--visible']]: isHoveringCard })}
                            >
                                <CustomIcon
                                    hasBackgroundBorderHover
                                    hasActiveState
                                    type={isFavorite ? 'checkmark' : 'plus-icon'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleFavorite();
                                    }}
                                    hasBackgroundHover
                                    color="white"
                                    tooltip={isFavorite ? 'Unfollow Label' : 'Follow Label'}
                                    tooltipBottomOffset={5}
                                    container="round"
                                    containerSize={32} />
                            </button>

                        )}
                    </div>
                    <CardDescription
                        isDescriptionSingleLine={isDescriptionSingleLine}
                        description={description}
                    />
                </div>
            </div>
        );
    };

    return (link ? (
        <AppLink href={link}>
            <a target={openInNewTab ? '_blank' : undefined}>
                {renderCard()}
            </a>
        </AppLink>
    ) : (
        <button type="button" onClick={handleCardPlay}>
            {renderCard()}
        </button>
    ));
}

export default memo(CardCreate);
