import { apiKey, Device } from '@bpm-web-app/api-client';
import { UpdateDevice } from '@bpm-web-app/supreme-api-sdk';
import * as uuid from 'uuid';
import { isAdminSession } from 'libs/api-client/src/lib/config';
import { UAParser } from 'ua-parser-js';
import Cookies from 'js-cookie';
import { isServerSide } from './isServerSide';

const uuidv4 = uuid.v4;

const { version } = require('../../../../package.json');

const supports = !isServerSide() && typeof window.indexedDB !== 'undefined';

/* eslint-disable @typescript-eslint/dot-notation */
const partnerName = process.env['NEXT_PUBLIC_PARTNER_NAME'];
const partnerToken = process.env['NEXT_PUBLIC_PARTNER_TOKEN'];
if (typeof partnerName === undefined || typeof partnerToken === undefined) {
    throw new Error('Partner name or token is missing from environment variables');
}
/* eslint-enable @typescript-eslint/dot-notation */

export class DeviceManager {
    static partnerName = partnerName as string;

    static partnerToken = partnerToken as string;

    static cookieName = 'bpm-device';

    static fingerprint: string;

    static fingerprintPromise: Promise<string>;

    private static resolveIsReady: () => void;

    static isReady = new Promise((resolve) => {
        DeviceManager.resolveIsReady = resolve as () => void;
    });

    private static getLocalDeviceId() {
        let storedId = Cookies.get(DeviceManager.cookieName);
        if (!storedId && localStorage && 'getItem' in localStorage) {
            storedId = localStorage.getItem(DeviceManager.cookieName) || undefined;
        }
        return storedId;
    }

    private static setLocalDeviceId(visitorId: string) {
        Cookies.set(DeviceManager.cookieName, visitorId, { expires: 365 * 2 });
        if (localStorage && 'setItem' in localStorage) {
            localStorage.setItem(DeviceManager.cookieName, visitorId);
        }
    }

    static async getFingerprint() {
        const storedId = DeviceManager.getLocalDeviceId();
        if (storedId) {
            DeviceManager.fingerprint = storedId;
            return storedId;
        }
        const val = uuidv4();
        DeviceManager.setLocalDeviceId(val);
        return val;
    }

    static getName() {
        const parser = new UAParser();
        const parts: string[] = [];
        if (parser.getOS().name) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parts.push(parser.getOS().name!);
        }
        if (parser.getDevice().vendor) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parts.push(parser.getDevice().vendor!);
        }
        if (parser.getDevice().model) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parts.push(parser.getDevice().model!);
        }
        if (parser.getBrowser().name) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parts.push(parser.getBrowser().name!);
        }
        if (parts.length > 0) {
            return parts.join(' ');
        }
        return undefined;
    }

    static async register() {
        if (!supports) return;
        await DeviceManager.initDevice();
        DeviceManager.resolveIsReady();
    }

    static async initDevice() {
        if (isAdminSession()) {
            return Promise.resolve();
        }
        try {
            const device = await DeviceManager.createUpdateDevice();
            return device;
        } catch (error) {
            return error;
        }
    }

    static async setDevicePushToken(fcmToken: string) {
        if (isAdminSession()) {
            return Promise.resolve();
        }
        const fingerprint = await DeviceManager.getFingerprint();
        return Device.updateDevice(fingerprint, {
            device_token: fcmToken,
            token_provider: UpdateDevice.TokenProviderEnum.Web,
            app_type: 'web_app',
            app_version: version,
            // eslint-disable-next-line @typescript-eslint/dot-notation
            build_version: process.env['CONFIG_BUILD_ID'] || 'local',
            // eslint-disable-next-line @typescript-eslint/dot-notation
            debug: process.env['NEXT_PUBLIC_RELEASE_STAGE'] !== 'production',
        } as any);
    }

    static async createUpdateDevice(fcmToken?: string) {
        if (isAdminSession()) {
            return Promise.resolve();
        }
        const fingerprint = await DeviceManager.getFingerprint();
        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return fetch(`${process.env['NEXT_PUBLIC_SUPREME_API_BASE_PATH']}/devices/${fingerprint}`, {
            method: 'PUT',
            body: JSON.stringify({
                app_version: version,
                app_type: 'web_app',
                // eslint-disable-next-line @typescript-eslint/dot-notation
                build_version: process.env['CONFIG_BUILD_ID'] || 'local',
                device_data_os: 'web',
                device_data_device_name: DeviceManager.getName(),
                // eslint-disable-next-line @typescript-eslint/dot-notation
                debug: process.env['NEXT_PUBLIC_RELEASE_STAGE'] !== 'production',
                request_iv: false,
            }),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'BPM-Integration': DeviceManager.partnerName,
                ...headers
            },
        }).then((res) => res.json()).then((res) => {
            return res.data;
        });
    }
}
