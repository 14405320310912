import { useContext, useMemo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { convertToPluralIfNeeded, createAppRoutes, DEFAULT_SOUNDS_LIMIT, fileDownload, getAbsolutePosition, isMobileNavigator, soundPackageToCreatePlayable, useApiErrorHandler, useOnClickOutside, showToast, Analytics, ViewportContext } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import {
    useAddSoundToDrive,
    useCreateDrive,
    useCreateLike,
    useDownloadSound,
    useDownloadSoundPackage,
    useGetCredits,
    useGetDrives,
    useGetSound,
    useGetSoundPackage,
    useReportSound,
    useDeleteDrive,
    useUpdateDriveName,
    useRemoveSoundFromDrive,
    useInfiniteSearchSound,
    useGetDrive,
    useGetPreset,
    useInfiniteSearchPresets,
} from '@bpm-web-app/swr-hooks';
import styles from './three-dots-sheet.module.css';
import ThreeDotsMenuItem, { IconType } from './three-dots-menu-item';
import { CreateThreeDotsSheetContext, CreateThreeDotsSheetOptionsKeys } from './create-three-dots-sheet.context';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import { ActionModal } from '../action-modal/action-modal';
import PlaylistsForm from '../../playlists-form/playlists-form';
import MyDrivesList from '../../my-drives-list/my-drives-list';
import { useActionModal } from '../action-modal/action-modal.context';
import Add from '../../../assets/icons/add.svg';
import BackArrow from '../../../assets/icons/chevron-left.svg';

type Option = {
    key: CreateThreeDotsSheetOptionsKeys;
    label: string;
    // eslint-disable-next-line max-len
    icon?: IconType;
    action: () => void;
    extraLabel?: string;
};

type ContainerStyle = {
    top?: number;
    left?: number;
};

const threeDotsIconClassName = 'js-three-dots-icon';

export function CreateThreeDotsSheet() {
    const { optionsToShow, shareUrl, actionTypeId, setCreateActionTypeId, actionType, leftPosition, topPosition,
        secondaryCreateActionTypeId, showOnlyDrives, object, move, renderLocation, onActionCompleted } =
        useContext(CreateThreeDotsSheetContext);

    const { width: viewPortWidth } = useContext(ViewportContext);
    const isMobile = viewPortWidth <= 767;

    const router = useRouter();
    const [activeKeyboardIndex, setActiveKeyboardIndex] = useState(-1);
    const [showReportTrack, setShowReportTrack] = useState(false);
    const [showDrives, setShowDrives] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [showEditDriveForm, setShowEditDriveForm] = useState<boolean>(false);
    const { mutate: updateCredits } = useGetCredits(true);
    const { data: drives, mutate: mutateDrives } = useGetDrives(showDrives || actionType === 'drive-sound' || actionType === 'sound', true);
    const { data: drive, mutate: mutateDrive } = useGetDrive(`${actionTypeId}`, actionType === 'drive');
    const [additionalMenuTopOffset, setAdditionalMenuTopOffset] = useState(0);

    const createDriveFolder = useCreateDrive();
    const deleteDrive = useDeleteDrive();
    const updateDriveName = useUpdateDriveName();
    const addSound = useAddSoundToDrive();
    const removeSoundFromDrive = useRemoveSoundFromDrive();

    const { data: soundPackage } = useGetSoundPackage(`${actionTypeId}`, actionType !== 'pack' && actionType !== 'mini-player' && actionType !== 'mini-player-demos');
    const { data: soundData } = useGetSound(`${actionTypeId}`, !['sound', 'drive-sound', 'download-sound'].includes(actionType), object?.type === 'Sound' ? object.obj : undefined);
    const { data: preset } = useGetPreset(`${actionTypeId}`, !['preset', 'drive-preset', 'download-preset'].includes(actionType), object?.type === 'Preset' ? object.obj : undefined);

    const sound = soundData || preset;
    const isPreset = actionType === 'preset' || actionType === 'drive-preset' || actionType === 'download-preset';

    const { mutate: mutateDriveSounds } = useInfiniteSearchSound(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
            drive_id: `${secondaryCreateActionTypeId}`,
        },
        actionType === 'drive-sound' && !!secondaryCreateActionTypeId
    );

    const { mutate: mutateDrivePresets } = useInfiniteSearchPresets(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
            drive_id: `${secondaryCreateActionTypeId}`,
        },
        actionType === 'drive-preset' && !!secondaryCreateActionTypeId
    );
    // ToDo: Temporary: !!
    const { mutate: mutateDrivePresetsLimit3 } = useInfiniteSearchPresets(
        {
            limit: 3,
            drive_id: `${secondaryCreateActionTypeId}`,
        },
        actionType === 'drive-preset' && !!secondaryCreateActionTypeId
    );

    const { playDemo } = useCreatePlayer();
    const { openModal: openActionModal, closeModal: closeActionModal } = useActionModal();

    useEffect(() => {
        if (showOnlyDrives) {
            setShowDrives(true);
        }
    }, [showOnlyDrives]);

    const handleCloseSheet = useCallback(() => {
        setCreateActionTypeId(-1);
    }, [setCreateActionTypeId]);

    useEffect(() => {
        router.events.on('routeChangeComplete', handleCloseSheet);
    }, [handleCloseSheet, router.events]);

    const errorHandler = useApiErrorHandler();

    const handleShowReportMedia = useCallback(async () => {
        Analytics.trackClick(`report:${actionType}`, actionTypeId.toLocaleString(), { location: 'create-3-dots' });
        setShowReportTrack(true);
    }, []);

    const { ref } = useOnClickOutside(false, (e) => {
        if (!showCreateForm && !showReportTrack && !showEditDriveForm) {
            const eventTarget = e.target;
            if (eventTarget instanceof Element) {
                const parentClass = `.${styles['three-dots-menu__container']}`;
                /* if clicked target with 3 dots */
                if (eventTarget.closest(parentClass)) {
                    return;
                }

                if (eventTarget.closest('.three-dots-button')) {
                    return;
                }

                /* if clicked on a direct parent of the svg icon */
                const eventTargetChildren = Array.from(eventTarget.children);
                if (eventTargetChildren.some((childElement) => childElement.classList.contains(threeDotsIconClassName))) {
                    return;
                }
            }

            handleCloseSheet();
        }
    });

    useEffect(
        () => () => handleCloseSheet(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const containerStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (isMobile) {
            return styleTemp;
        }
        if (leftPosition !== null) {
            styleTemp.left = leftPosition;
        }
        if (topPosition !== null) {
            styleTemp.top = topPosition;
        }
        return styleTemp;
    }, [leftPosition, topPosition, isMobile]);

    const leftSideStyle = useMemo(() => {
        const styleTemp: ContainerStyle = {};
        if (isMobile) {
            return styleTemp;
        }
        if (topPosition !== null) {
            styleTemp.top = additionalMenuTopOffset;
        } else {
            styleTemp.top = additionalMenuTopOffset;
        }
        return styleTemp;
    }, [additionalMenuTopOffset, topPosition, isMobile]);

    const addSoundToDrive = useCallback(
        async (driveId: string) => {
            if (sound?.data.id) {
                try {
                    await addSound(driveId, sound.data.id);
                    mutateDrives();
                    showToast({ type: 'success', title: `${isPreset ? 'Preset' : 'Sound'} added to Drive successfully.`, buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${driveId}` });
                } catch (error) {
                    errorHandler({ error, message: `Failed to add ${isPreset ? 'preset' : 'sound'} to drive.` });
                }

                handleCloseSheet();
            }
        },
        [addSound, errorHandler, handleCloseSheet, mutateDrives, sound?.data.id, isPreset]
    );

    const handleCreateDrive = useCallback(
        async (name: string) => {
            try {
                const response = await createDriveFolder({ name });
                mutateDrives();
                showToast({ type: 'success', message: 'Drive created successfully.', buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${response.data.id}` });
            } catch (error) {
                errorHandler({ error, message: 'Failed to create new drive folder' });
            }

            handleCloseSheet();
        },
        [createDriveFolder, errorHandler, handleCloseSheet, mutateDrives]
    );

    const CreateDriveForm = useMemo(
        () => (
            <PlaylistsForm
                type="CreateNewDrive"
                text="Name your drive in the box below and click 'Create New Drive.'"
                formAction={handleCreateDrive}
                close={() => setShowCreateForm(false)}
            />
        ),
        [handleCreateDrive]
    );

    const handleEditDriveName = useCallback(
        async (name: string) => {
            try {
                const response = await updateDriveName(`${actionTypeId}`, { name });
                mutateDrives();
                mutateDrive(drive ? { ...drive, data: { ...drive.data, name } } : undefined, { revalidate: true });
                showToast({ type: 'success', message: 'Renamed successfully.', buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${response.data.id}` });
            } catch (error) {
                errorHandler({ error, message: 'Failed to rename drive folder' });
            }

            handleCloseSheet();
        },
        [actionTypeId, drive, errorHandler, handleCloseSheet, mutateDrive, mutateDrives, updateDriveName]
    );

    const EditDriveForm = useMemo(
        () => (
            <PlaylistsForm
                type="EditDriveName"
                text={`Enter a new name for ${drive?.data.name} below.`}
                formAction={handleEditDriveName}
                close={() => setShowEditDriveForm(false)}
            />
        ),
        [handleEditDriveName, drive]
    );

    const handlePlayDemo = useCallback(
        (alternativeDemo: boolean) => {
            if (soundPackage?.data) {
                const packInfo = soundPackageToCreatePlayable(soundPackage.data.pack);

                if (alternativeDemo) {
                    packInfo.demo_file_url = soundPackage.data.pack.demo_file_url_2;
                    packInfo.demo_wave = soundPackage.data.pack.demo_wave_2;
                    packInfo.playSecondDemo = true;
                }

                playDemo(packInfo);
            }

            handleCloseSheet();
        },
        [handleCloseSheet, playDemo, soundPackage?.data]
    );

    const likeType = useMemo(() => {
        switch (actionType) {
            case 'pack':
            case 'mini-player':
                return 'soundPackage';
            case 'curated-set':
                return 'curated';
            case 'drive-sound':
            case 'sound':
                return 'sound';
            case 'drive-preset':
            case 'download-preset':
            case 'preset':
                return 'preset';
            case 'label':
                return 'label';
            default:
                return 'label';
        }
    }, [actionType]);

    const { isLiked, likeDislike } = useCreateLike(likeType);
    const downloadSound = useDownloadSound();
    const reportSound = useReportSound();
    const downloadPack = useDownloadSoundPackage();

    const isFavorite = useMemo(() => isLiked(`${actionTypeId}`), [actionTypeId, isLiked]);

    const handleFavorite = useCallback(() => {
        likeDislike(`${actionTypeId}`);
        handleCloseSheet();
    }, [actionTypeId, handleCloseSheet, likeDislike]);

    const handleDownloadPack = useCallback(async () => {
        try {
            handleCloseSheet();
            const response = await showToast({
                promise: downloadPack(`${actionTypeId}`, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            fileDownload(response.data.url);
            Analytics.trackClick('download', actionTypeId.toString(), { location: 'create-3-dot' });
            updateCredits();
        } catch (error) {
            errorHandler({ error, message: 'Failed to download pack' });
        }
    }, [actionTypeId, downloadPack, errorHandler, handleCloseSheet, updateCredits, router]);

    const handleDownloadHelper = useCallback(
        async (id: string) => {
            try {
                const response = await showToast({
                    promise: downloadSound(id, router.asPath),
                    noProgress: true,
                    successText: 'Download successful.',
                    preventErrorToast: true
                });
                fileDownload(response.data.url);
                Analytics.trackClick('download', id, { location: 'create-3-dots' });
                updateCredits();
                onActionCompleted?.();
            } catch (error) {
                errorHandler({ error, message: 'Failed to download sound' });
            }
        },
        [downloadSound, errorHandler, updateCredits, router, onActionCompleted]
    );

    const handleDownload = useCallback(async () => {
        handleCloseSheet();
        if (!sound?.data) return;
        handleDownloadHelper(sound.data.id);
    }, [sound?.data, handleDownloadHelper, handleCloseSheet]);

    const handleDownloadMidi = useCallback(async () => {
        handleCloseSheet();
        if (!sound?.data) return;
        handleDownloadHelper(sound.data.midi.id);
    }, [sound?.data, handleDownloadHelper, handleCloseSheet]);

    const handleSimilar = useCallback(() => {
        handleCloseSheet();
        if (actionType === 'pack' || actionType === 'mini-player') {
            /* secondaryCreateActionTypeId used here to use a pack's slug
             * (user-friendly format) in the URL instead of the "meaningless" id (in UUID form) */
            router.push(createAppRoutes.similarPacks(`${secondaryCreateActionTypeId || actionTypeId}`));
            return;
        }

        router.push(createAppRoutes.similarSounds(`${actionTypeId}`));
    }, [actionType, actionTypeId, handleCloseSheet, router, secondaryCreateActionTypeId]);

    const handleGoToPack = useCallback(() => {
        handleCloseSheet();

        if (!sound?.data) return;
        router.push(createAppRoutes.packsSlug(sound.data.sound_package_id));
    }, [handleCloseSheet, router, sound?.data]);

    const handleReportSound = useCallback(async () => {
        if (!sound?.data) return;

        try {
            await reportSound(sound.data.id);
            showToast({ type: 'success', title: `${isPreset ? 'preset' : 'sound'} reported successfully.` });
        } catch (error) {
            errorHandler({ error, message: 'Failed to report sound' });
        }

        setShowReportTrack(false);
        handleCloseSheet();
    }, [sound?.data, errorHandler, handleCloseSheet, reportSound, isPreset]);

    const handleDeleteDrive = useCallback(async () => {
        openActionModal({
            headerTitle: 'Delete Drive',
            title: 'Are you sure you want to delete this Drive?',
            confirmButtonText: "I'm sure",
            cancelButtonText: 'Cancel',
            shouldCloseOnOverlayClick: true,
            onConfirm: async () => {
                try {
                    await deleteDrive(`${actionTypeId}`);
                    mutateDrives();
                    showToast({ type: 'success', message: 'Deleted successfully.' });
                    closeActionModal();
                    router.replace(createAppRoutes.myDrive);
                } catch (error) {
                    errorHandler({ error, message: 'Failed to delete Drive' });
                }
            },
            onClose: closeActionModal,
        });
    }, [actionTypeId, closeActionModal, deleteDrive, errorHandler, mutateDrives, openActionModal, router]);

    const handleRemoveFromDrive = useCallback(async () => {
        handleCloseSheet();
        try {
            await removeSoundFromDrive(`${secondaryCreateActionTypeId}`, `${actionTypeId}`);
            await mutateDrives();
            await mutateDriveSounds();
            await mutateDrivePresets();
            await mutateDrivePresetsLimit3();
            showToast({ type: 'success', title: `Removed ${isPreset ? 'preset' : 'sound'} from drive.` });
        } catch (err) {
            errorHandler({ error: err });
        }
        // Remove from drive
        // Update drive cache for sound count and drive detail cache
    }, [actionTypeId, errorHandler, handleCloseSheet, mutateDriveSounds, mutateDrives, mutateDrivePresets, mutateDrivePresetsLimit3, removeSoundFromDrive, secondaryCreateActionTypeId, isPreset]);

    const copyUrl = useCallback(() => {
        Analytics.trackClick(`share:${actionType}`, actionTypeId.toLocaleString(), { location: 'create-3-dots' });
        // eslint-disable-next-line @typescript-eslint/dot-notation
        navigator.clipboard?.writeText(process.env['NEXT_PUBLIC_RELEASE_STAGE'] === 'local' ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}${shareUrl}` : `${window.location.protocol}//${window.location.hostname}${shareUrl}`);
        showToast({ type: 'success', message: 'Copied to clipboard.' });
        handleCloseSheet();
    }, [actionType, actionTypeId, handleCloseSheet, shareUrl]);

    const handleShowDrives = useCallback((e?: React.MouseEvent<HTMLElement>) => {
        setShowDrives(true);
        if (e) { setAdditionalMenuTopOffset(e.currentTarget.offsetTop); }
    }, [setShowDrives, setAdditionalMenuTopOffset]);

    const allOption = useMemo<Option[]>(
        () => [
            {
                key: 'play-demo',
                icon: 'playDemo',
                label: 'Play Demo',
                action: () => handlePlayDemo(false),
            },
            {
                key: 'play-demo-2',
                icon: 'playDemo',
                label: 'Play Demo 2',
                action: () => handlePlayDemo(true),
            },
            {
                key: 'demo-01',
                icon: 'playDemo',
                label: 'Play Demo 01',
                action: () => handlePlayDemo(false),
            },
            {
                key: 'demo-02',
                icon: 'playDemo',
                label: 'Play Demo 02',
                action: () => handlePlayDemo(true),
            },
            {
                key: 'add-to-favorites',
                icon: isFavorite ? 'favorite' : 'unFavorite',
                label: isFavorite ? 'Remove from favorites' : 'Add to Favorites',
                action: handleFavorite,
            },
            {
                key: 'find-similar',
                icon: 'findSimilar',
                label: 'Find Similar',
                action: handleSimilar,
            },
            {
                key: 'download',
                icon: 'download',
                label: 'Download',
                action: handleDownload,
            },
            {
                key: 'download-credits',
                label: 'Download',
                action: handleDownload,
                icon: null,
                extraLabel: convertToPluralIfNeeded(sound?.data?.pricing?.value, 'Credit')
            },
            {
                key: 'download-preset-credits',
                label: 'Download Preset',
                action: handleDownload,
                icon: null,
                extraLabel: convertToPluralIfNeeded(sound?.data?.pricing?.value, 'Credit')
            },
            {
                key: 'download-midi-credits',
                label: 'Download MIDI',
                icon: null,
                action: handleDownloadMidi,
                extraLabel: convertToPluralIfNeeded(sound?.data.midi?.pricing?.value, 'Credit')
            },
            {
                key: 'download-midi',
                label: 'Download MIDI',
                icon: 'midi',
                action: handleDownloadMidi,
            },
            {
                key: 'download-pack',
                icon: 'download',
                label: 'Download Pack',
                action: handleDownloadPack,
            },
            {
                key: 'go-to-pack',
                icon: 'goTo',
                label: 'Go to Pack',
                action: handleGoToPack,
            },
            {
                key: 'add-to-drive',
                icon: 'addToDrive',
                label: 'Add to Drive',
                action: handleShowDrives
            },
            {
                key: 'share',
                icon: 'share',
                label: 'Share',
                action: copyUrl,
            },
            {
                key: 'report',
                icon: 'reportTrack',
                label: 'Report',
                action: handleShowReportMedia,
            },
            {
                key: 'edit-drive-name',
                icon: 'editDriveName',
                label: 'Edit Drive name',
                action: () => setShowEditDriveForm(true),
            },
            {
                key: 'delete-drive',
                icon: 'deleteDrive',
                label: 'Delete Drive',
                action: handleDeleteDrive,
            },
            {
                key: 'remove-from-drive',
                icon: 'deleteTrack' as IconType,
                label: 'Remove from drive',
                action: handleRemoveFromDrive,
            }
        ],
        [
            isFavorite,
            handleFavorite,
            handleSimilar,
            handleDownload,
            handleDownloadMidi,
            handleDownloadPack,
            handleGoToPack,
            handleShowReportMedia,
            handleDeleteDrive,
            handleRemoveFromDrive,
            copyUrl,
            handlePlayDemo,
            handleShowDrives,
            sound
        ]
    );

    useEffect(() => {
        if (shareUrl && !optionsToShow.includes('share')) {
            optionsToShow.push('share');
        }
    }, [shareUrl, optionsToShow]);

    const options = useMemo(
        () =>
            allOption.filter((option) => {
                if (option.key === 'download-midi' && actionType === 'sound' && !sound?.data?.midi) {
                    return false;
                }

                if (option.key === 'download-midi-credits' && (actionType === 'download-sound' || actionType === 'download-preset') && !sound?.data?.midi) {
                    return false;
                }

                if (!optionsToShow.includes(option.key)) return false;

                // NOTE: Remove download options to mobile devices and mobile view
                if ((isMobile || isMobileNavigator) && (option.key === 'download-midi' || option.key === 'download')) {
                    return false;
                }

                return true;
            }),
        [actionType, allOption, isMobile, optionsToShow, sound?.data?.midi]
    );

    const handleUserKeyPress = useCallback(
        (event) => {
            if (document.activeElement.className.includes('input')) {
                return;
            }

            const { key } = event;

            if (key === 'ArrowDown' && options.length > activeKeyboardIndex + 1) {
                event.preventDefault();
                setActiveKeyboardIndex(activeKeyboardIndex + 1);
                return;
            }

            if (key === 'ArrowUp' && options.length > 0) {
                event.preventDefault();
                setActiveKeyboardIndex(activeKeyboardIndex - 1);
                return;
            }

            if (key === 'Enter' && activeKeyboardIndex > -1) {
                event.preventDefault();
                if (showDrives) {
                    if (activeKeyboardIndex === 0) {
                        setShowCreateForm(true);
                        return;
                    }
                    addSoundToDrive(drives[activeKeyboardIndex - 1].id);
                } else {
                    options[activeKeyboardIndex].action();
                }
            }
        },
        [activeKeyboardIndex, addSoundToDrive, drives, options, showDrives]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        if (isMobile || renderLocation !== 'app') {
            return;
        }
        if (ref.current && ref.current.children.length > 0) {
            const { width, height } = ref.current.children[0].getBoundingClientRect();
            const { x, y } = getAbsolutePosition(ref.current);
            let deltaX = 0;
            let deltaY = 0;
            if ((x - width) < 0) {
                deltaX = (width - leftPosition);
            }
            if ((y + height) > window.innerHeight) {
                deltaY = window.innerHeight - (y + height);
            }
            if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                move(leftPosition + deltaX, topPosition + deltaY);
            }
        }
    }, [ref, leftPosition, topPosition, options, move, isMobile, renderLocation]);

    return (
        <div
            ref={ref}
            style={containerStyle}
            className={classNames(styles['three-dots-menu__container'], {
                [styles['three-dots-menu__container--bottom-bar-visible']]: renderLocation === 'notification'
            })}>
            <ActionModal
                headerTitle="Report sound"
                onClose={() => {
                    setShowReportTrack(false);
                    handleCloseSheet();
                }}
                onConfirm={handleReportSound}
                confirmButtonText="Report"
                title={`Are you sure you want to report an issue with ${sound?.data.name}?`}
                isOpen={showReportTrack}
            />
            {showCreateForm && CreateDriveForm}
            {showEditDriveForm && EditDriveForm}
            <div className={classNames(styles['three-dots-menu__list'], { [styles['three-dots-menu__list--open']]: actionTypeId !== -1 })}>
                {showDrives && (
                    <ul className={classNames(styles['three-dots-menu__list--display-option'])} style={leftSideStyle}>
                        {isMobile && (
                            <li className={classNames(styles['three-dots-menu__list-item'], styles['three-dots-menu__list-item__title'])}>
                                <button
                                    type="button"
                                    className={styles['three-dots-menu__list-item__button']}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowDrives(false);
                                    }}
                                >
                                    <BackArrow />
                                    <span>Go Back</span>
                                </button>
                            </li>
                        )}
                        <li className={classNames(styles['three-dots-menu__list-item'], styles['three-dots-menu__list-item__title'])}>
                            <button
                                type="button"
                                className={classNames(styles['three-dots-menu__list-item__button'], { [styles['three-dots-menu__list-item__button--active']]: activeKeyboardIndex === 0 })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowCreateForm(true);
                                }}
                            >
                                <Add />
                                <span>Create drive</span>
                            </button>
                        </li>
                        <MyDrivesList drives={drives} highlightIndex={activeKeyboardIndex - 1} selectCategory={(id) => addSoundToDrive(id)} />
                    </ul>
                )}
                {((isMobile && !showDrives) || !isMobile) && (
                    <ul>
                        {options.map((option, index) => (
                            <ThreeDotsMenuItem
                                key={option.key}
                                keyText={option.key}
                                legend={option.label}
                                action={option.action}
                                icon={option.icon}
                                extraLabel={option.extraLabel}
                                highlight={activeKeyboardIndex === index}
                            />
                        ))}
                    </ul>
                )}
                {isMobile && (
                    <button
                        type="button"
                        className={classNames(styles['three-dots-menu__list-item__button--cancel-btn'])}
                        onClick={() => {
                            handleCloseSheet();
                        }}
                    >
                        Cancel
                    </button>
                )}
            </div>
        </div>
    );
}

export default CreateThreeDotsSheet;
