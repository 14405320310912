import { createAppRoutes } from '@bpm-web-app/utils';
import CuratedSetsActive from '../../assets/icons/curated-sets-active.svg';
import CuratedSets from '../../assets/icons/curated-sets.svg';
import HeartOutlineActive from '../../assets/icons/favorite-heart-outline-nav-active.svg';
import HeartOutline from '../../assets/icons/favorite-heart-outline-nav.svg';
import HomeActive from '../../assets/icons/home-active.svg';
import Home from '../../assets/icons/home.svg';
import LabelsActive from '../../assets/icons/create/labels-active.svg';
import Labels from '../../assets/icons/create/labels.svg';
import Midi from '../../assets/icons/create/midi.svg';
import MidiActive from '../../assets/icons/create/midi-active.svg';
import PlugActive from '../../assets/icons/create/synth-presets-filled.svg';
import Plug from '../../assets/icons/create/plug.svg';
import Drive from '../../assets/icons/create/my-drives-outline.svg';
import DriveActive from '../../assets/icons/create/my-drives-filled.svg';
import MyPlaylistsActive from '../../assets/icons/my-playlists-active.svg';
import MyPlaylists from '../../assets/icons/my-playlists.svg';
import NewReleaseActive from '../../assets/icons/new-release-active.svg';
import NewRelease from '../../assets/icons/new-release.svg';
import ExclusivePlaylistsActive from '../../assets/icons/playlist-item-active.svg';
import ExclusivePlaylists from '../../assets/icons/playlist-item.svg';
import Trending from '../../assets/icons/trending-icon.svg';
import TrendingActive from '../../assets/icons/trending-filled.svg';
import Instruments from '../../assets/icons/create/instruments.svg';
import InstrumentsActive from '../../assets/icons/create/instruments-active.svg';
import ArtistPortalIcons from '../shared/bpm-icons/artist-portal-icons';
import Genre from '../../assets/icons/genre-icon.svg';
import GenreActive from '../../assets/icons/genre-active.svg';
import MyCrateIcon from '../../assets/icons/my-crate-icon.svg';
import MyCrateActiveIcon from '../../assets/icons/mycrate-filled.svg';

export const createNavItems = () => [
    {
        title: 'Home',
        icon: <Home />,
        activeIcon: <HomeActive />,
        path: '/',
    },
    {
        title: 'Newest Packs',
        icon: <NewRelease />,
        activeIcon: <NewReleaseActive />,
        path: createAppRoutes.newestPacks,
    },
    {
        title: 'Trending',
        icon: <Trending />,
        activeIcon: <TrendingActive />,
        path: createAppRoutes.trending,
    },
    {
        title: 'Curated Packs',
        icon: <CuratedSets />,
        activeIcon: <CuratedSetsActive />,
        path: createAppRoutes.curated,
    },
    {
        title: 'MIDI',
        icon: <Midi />,
        activeIcon: <MidiActive />,
        path: createAppRoutes.midi,
    },
    {
        title: 'Synth Presets',
        icon: <Plug />,
        activeIcon: <PlugActive />,
        path: createAppRoutes.presets,
    },
    {
        title: 'Instruments',
        icon: <Instruments />,
        activeIcon: <InstrumentsActive />,
        path: createAppRoutes.instruments,
    },
    {
        title: 'Genres',
        icon: <Genre />,
        activeIcon: <GenreActive />,
        path: createAppRoutes.genres,
    },
];

export const createMyLibraryNavItems = () => [
    {
        title: 'Favorites',
        icon: <HeartOutline />,
        activeIcon: <HeartOutlineActive />,
        path: createAppRoutes.favorites,
        droppableId: 'favorites',
    },
    {
        title: 'My Drives',
        icon: <Drive />,
        activeIcon: <DriveActive />,
        mainPath: createAppRoutes.myDrive,
        droppableId: 'my-drive',
    },
];

export const supremeNavItems = () => [
    {
        title: 'Home',
        icon: <Home />,
        activeIcon: <HomeActive />,
        path: '/',
    },
    {
        title: 'Trending',
        icon: <Trending />,
        activeIcon: <TrendingActive />,
        path: '/trending',
    },
    {
        title: 'New Releases',
        icon: <NewRelease />,
        activeIcon: <NewReleaseActive />,
        path: '/releases',
    },
    {
        title: 'Curated Sets',
        icon: <CuratedSets />,
        activeIcon: <CuratedSetsActive />,
        path: '/curated-sets',
    },
    {
        title: 'Exclusive Playlists',
        icon: <ExclusivePlaylists />,
        activeIcon: <ExclusivePlaylistsActive />,
        path: '/exclusive-playlists',
    },
    {
        title: 'Genres',
        icon: <Genre />,
        activeIcon: <GenreActive />,
        path: '/genres',
    },
];

export const supremeMyLibraryNavItems = () => [
    {
        title: 'Your Playlists',
        icon: <MyPlaylists />,
        activeIcon: <MyPlaylistsActive />,
        mainPath: '/my-playlists',
        droppableId: 'my-playlists',
    },
    {
        title: 'Favorites',
        icon: <HeartOutline />,
        activeIcon: <HeartOutlineActive />,
        path: '/favorites',
        droppableId: 'favorites',
    },
    {
        title: 'My Crate',
        icon: <MyCrateIcon />,
        activeIcon: <MyCrateActiveIcon />,
        path: '/crate',
        droppableId: 'my-crate',
    },
];

export const artistPortalNavItems = () => [
    {
        title: 'Home',
        icon: <ArtistPortalIcons.HomeFillIcon />,
        activeIcon: <ArtistPortalIcons.HomeOutlineIcon />,
        path: '/dashboard#home',
    },
    {
        title: 'Your Tracks',
        icon: <ArtistPortalIcons.YourTracksOutlineIcon />,
        activeIcon: <ArtistPortalIcons.YourTracksFillIcon />,
        path: '/dashboard#your-tracks',
    },
    {
        title: 'Top Locations',
        icon: <ArtistPortalIcons.LocationsOutlineIcon />,
        activeIcon: <ArtistPortalIcons.LocationsFillIcon />,
        path: '/dashboard#locations',
    },
    {
        title: 'Engagement',
        icon: <ArtistPortalIcons.EngagementOutlineIcon />,
        activeIcon: <ArtistPortalIcons.EngagementFillIcon />,
        path: '/dashboard#engagement',
    },
    {
        title: 'Similar Artists',
        icon: <ArtistPortalIcons.SimilarArtistsOutlineIcon />,
        activeIcon: <ArtistPortalIcons.SimilarArtistsFillIcon />,
        path: '/dashboard#artists',
    },
    {
        title: 'Sources',
        icon: <ArtistPortalIcons.SourcesOutlineIcon />,
        activeIcon: <ArtistPortalIcons.SourcesFillIcon />,
        path: '/dashboard#sources',
    },
    {
        title: 'Manage Profile',
        icon: <ArtistPortalIcons.EditProfileOutlineIcon />,
        activeIcon: <ArtistPortalIcons.EditProfileFillIcon />,
        path: '/manage-profile',
    },
];
