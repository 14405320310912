import { appendQueryParams, SeeMoreItem, useUniqueArray, CreateCardCarouselItem, convertSoundPackageToSeeMoreLayoutProps, convertCuratedToSeeMoreLayoutProps, convertLabelToSeeMoreProps } from '@bpm-web-app/utils';
import { Fragment, ReactNode, useMemo } from 'react';
import { useCreateLike } from '@bpm-web-app/swr-hooks';
import classNames from 'classnames';
import { CuratedSet, Label, SoundPackage } from '@bpm-web-app/create-api-sdk';
import CardGrid from '../shared/card-grid/card-grid';
import CardCreate from '../shared/card/card-create';
import { CreateListGridItem } from '../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../shared/ui/list-grid/list-grid';
import BreakpointView from '../shared/ui/breakpoint-view/breakpoint-view';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';

import styles from './see-more-layout.module.css';
import { CreateActionType } from '../shared/three-dots-sheet/create-three-dots-sheet.context';
import CardGhostLoading from '../shared/card/card-ghost-loading';
import GhostElement from '../shared/ghost-element/ghost-element';
import { CreateCardContentTypeWithoutActions } from '../shared/card/card-image-overlay/card-image-overlay';
import { PackCardCreate } from '../shared/card/pack-card-create/pack-card-create';
import { CuratedSetCardCreate } from '../shared/card/curated-set-card-create/curated-set-card-create';

type BaseProps = {
    count?: number;
    pageTitle?: string;
    contentType: CreateActionType | CreateCardContentTypeWithoutActions;
    loading?: boolean
};

type SeeMoreLayoutNoChildrenProps = BaseProps & {
    itemBasePath?: string;
    data?: SoundPackage[] | CuratedSet[] | Label[] | SeeMoreItem[];
    desktopChildren?: never;
    mobileChildren?: never;
};

type SeeMoreLayoutChildrenProps = BaseProps & {
    itemBasePath?: never;
    data?: never;
    desktopChildren: ReactNode;
    mobileChildren: ReactNode;
};

type SeeMoreLayoutProps = SeeMoreLayoutNoChildrenProps | SeeMoreLayoutChildrenProps;

/** Use this component only on Create App */
export function SeeMoreLayout({ contentType, itemBasePath, data: rawData, pageTitle, count = undefined, mobileChildren, desktopChildren, loading }: SeeMoreLayoutProps) {
    const likeType = useMemo(() => {
        switch (contentType) {
            case 'pack':
                return 'soundPackage';
            case 'curated-set':
                return 'curated';
            case 'sound':
                return 'sound';
            case 'label':
                return 'label';
            case 'contest':
            case 'news':
                return undefined;
            default:
                return 'label';
        }
    }, [contentType]);

    const { isLiked, likeDislike } = useCreateLike(likeType);

    const ghostLoadingItems = useUniqueArray(10);

    const seeMoreItems = useMemo((): SeeMoreItem[] => {
        switch (contentType) {
            case 'pack':
                return (rawData as SoundPackage[])?.map((s) => convertSoundPackageToSeeMoreLayoutProps(s));
            case 'curated-set':
                return (rawData as CuratedSet[])?.map((s) => convertCuratedToSeeMoreLayoutProps(s));
            case 'label':
                return (rawData as Label[])?.map((s) => convertLabelToSeeMoreProps(s));
            default:
                return rawData as SeeMoreItem[];
        }
    }, [contentType, rawData]);

    const renderCards = () => {
        switch (contentType) {
            case 'pack':
                return rawData ? (
                    rawData.map((item) => (
                        <PackCardCreate item={item as unknown as SoundPackage} items={rawData as unknown as SoundPackage[]} />
                    ))
                ) : null;
            case 'curated-set':
                return rawData ? (
                    rawData.map((item) => (
                        <CuratedSetCardCreate item={item as unknown as CuratedSet} items={rawData as unknown as CuratedSet[]} />
                    ))
                ) : null;
            case 'label':
                return null;
            default:
                return (rawData as SeeMoreItem[]) ? (
                    (rawData as SeeMoreItem[]).map((item) => (
                        <CardCreate
                            hasExtraDemo={!!item.demo_file_url_2}
                            contentType={contentType}
                            key={item.id}
                            id={`${item.id}`}
                            title={item.title}
                            description={item.description}
                            imageUrl={appendQueryParams(item.image_url, { key: 'dw', value: 264 })}
                            imageUrl2x={appendQueryParams(item.image_url, { key: 'dw', value: 528 })}
                            link={`${itemBasePath}/${item.slug}`}
                            cardSize="small"
                            label={item.label}
                            isFavorite={isLiked(String(item.id))}
                            onFavorite={likeDislike}
                            shareURL={item.shareUrl}
                            badge={item.badge}
                            items={rawData as unknown as CreateCardCarouselItem[]}
                        />
                    ))
                ) : null;
        }
    };

    if (mobileChildren && desktopChildren) {
        return (
            <article className="spacing--top">
                {pageTitle ? (
                    <div className={styles['see-more-layout__title']}>
                        <SecondaryPageTitle title={pageTitle} counter={`${count ?? ''}`} />
                    </div>
                ) : null}
                <BreakpointView desktopChildren={<div className={classNames('spacing--horizontal', 'spacing--top')}>{desktopChildren}</div>} mobileChildren={mobileChildren} />
            </article>
        );
    }

    /* TODO: consider adding ghost loading to this layout */
    return (
        <article className="spacing__window">
            {pageTitle ? (
                <SecondaryPageTitle title={pageTitle} counter={`${count ?? ''}`} noPadding />
            ) : null}
            <div className={styles['see-more-layout__mobile']}>
                <ListGrid>
                    {loading && ghostLoadingItems.map((uuid) => (
                        <GhostElement
                            isRow
                            key={`see-more-layout-${uuid}`}
                            linesWidthArray={[90, 70]}
                            squareWidth={56}
                            itemClass={styles['see-more-layout__mobile-loading-item']}
                        />
                    ))}
                    {!loading && seeMoreItems && (seeMoreItems).map((item) => (
                        <Fragment key={item.id}>
                            <CreateListGridItem
                                id={item.id}
                                title={item.title}
                                imageUrl={appendQueryParams(item.image_url, { key: 'dw', value: 56 })}
                                imageUrl2x={appendQueryParams(item.image_url, { key: 'dw', value: 112 })}
                                subtitle={item.description ?? item.label}
                                link={itemBasePath ? `${itemBasePath}/${item.slug}` : undefined}
                                contentType={contentType}
                            />
                        </Fragment>
                    ))}
                </ListGrid>
            </div>
            <div className={styles['see-more-layout__desktop']}>
                <CardGrid cardType="card-small">
                    {loading && ghostLoadingItems.map((uuid) => <CardGhostLoading key={`card-ghost-${uuid}`} cardSize="small" linesWidthArray={[90, 70]} lineHeight={10} />)}
                    {!loading && renderCards()}
                </CardGrid>
            </div>
        </article>
    );
}

export default SeeMoreLayout;
